/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'

import moment from 'moment'
import { baseUrl, APIURL } from "../../../config/config";

import { parseCookies } from "nookies";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';

const TicketView: FC = () => {
    const navigate = useNavigate();
    const params = useParams()
    const { id } = params

    const cookies = parseCookies();
    const token = cookies?.token;
    const user = JSON.parse(cookies?.user);

    const [loading, setLoading] = useState(true);
    const [conLoading, setConLoading] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);
    const [logoImg, setLogoImg] = useState("");
    const [userInfo, setUserInfo] = useState([]);
    const [cats, setCats] = useState([]);
    const [ticketView, setTicketView] = useState<any>([]);
    const [ticket, setTicket] = useState({
        ticket_no: id,
        comment: "",
    });

    const fetchDatas = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var requestOptions: any = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        const getUser = await fetch(`${APIURL}/me`, requestOptions);
        const userData = await getUser.json();
        console.log('userData', userData);

        if (userData.success == true) {
            setUserInfo(userData?.data?.user);
        }

        const categories = await fetch(`${APIURL}/admin/ticket-categories`, requestOptions);
        const catresult = await categories.json();
        if (catresult.success == true) {
            setCats(catresult?.data);
        }

        const ticketData = await fetch(`${APIURL}/admin/view-ticket/${id}`, requestOptions);
        const TVResult = await ticketData.json();
        if (TVResult.success == true) {
            setTicketView(TVResult?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDatas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateTicket = (e: { target: { name: any; value: any } }) => {
        setTicket({ ...ticket, [e.target.name]: e.target.value });
    };

    async function SubmitHandler(e: any) {
        e.preventDefault();
        setConLoading(true);

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions: any = {
            method: "POST",
            body: JSON.stringify({
                ...ticket,
            }),
            headers: myHeaders,
            redirect: "follow",
        };

        const response = await fetch(`${APIURL}/admin/add-ticket-comment`, requestOptions);
        const result = await response.json();

        // console.log("response.status: ", response);
        if (response.status == 200) {
            if (result.success == true) {
                toast.success(result.message);
                fetchDatas();
                setTicket({ ...ticket, comment: "" });
            }
        } else if (response.status == 415) {
            // console.log("message", result);
            if (result?.message?.name?.length > 0) {
                toast.error(result?.message?.name[0]);
            }

            if (result?.message?.points?.length > 0) {
                toast.error(result?.message?.points[0]);
            }
        }

        setConLoading(false);
    }

    // console.log('brand', brand)

    return (
        <>
            <PageTitle>Ticket View</PageTitle>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className="col-xl-12">
                    <div className="card card-xl-stretch">
                        <div className="card-header border-0 bg-primary py-5">
                            <h3 className="card-title fw-bolder text-white">
                                Ticket Comment
                            </h3>
                        </div>
                        <div className="card-body p-2">
                            <form className="p-4 z-40" onSubmit={SubmitHandler}>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            <label className="form-label fs-6 fw-bolder text-dark">Name</label>
                                            <input className="form-control form-control-lg form-control-solid" type="text"
                                                value={user.fullname}
                                                autoComplete="off"
                                                readOnly
                                                disabled
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            <label className="form-label fs-6 fw-bolder text-dark">Email Address</label>
                                            <input className="form-control form-control-lg form-control-solid" type="text"
                                                value={user.email}
                                                autoComplete="off"
                                                readOnly
                                                disabled
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <label className="form-label fs-6 fw-bolder text-dark">Comment</label>
                                    <textarea
                                        defaultValue=""
                                        name="comment"
                                        value={ticket.comment}
                                        onChange={updateTicket}
                                        className="form-control form-control-lg form-control-solid"
                                        rows={4}
                                        cols={40}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                </div>

                                <div className='row mb-4'>
                                    {conLoading ? (
                                        <>
                                            <button className="btn btn-lg btn-primary mb-5" disabled style={{ width: '180px' }}>
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button className="btn btn-lg btn-primary mb-5" style={{ width: '180px' }}>
                                                Submit
                                            </button>
                                        </>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {
                    loading ?
                        <>
                            <div className="col-xl-12 d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </> : <>

                            <div className="col-xl-12">
                                <div className="card card-xl-stretch">
                                    <div className="card-header border-0 py-5">
                                        <div className='d-flex align-items-center mb-5'>
                                            <div className='d-flex align-items-center flex-grow-1'>
                                                <div className='symbol symbol-45px me-5'>
                                                    <img src={toAbsoluteUrl(ticketView?.photo_url)} alt='' />
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <h3 className="card-title text-gray-800 fw-bolder text-white">
                                                        {ticketView?.user.f_name} {ticketView?.user.l_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-0 text-hover-primary fw-semibold'>
                                            {moment(ticketView.created_at).format('LLLL')}
                                        </div>
                                    </div>
                                    <div className="card-body p-5">
                                        <p className="p-5">{ticketView.issue_text}</p>
                                        {
                                            ticketView.image == '' ? <></> :
                                                <>
                                                    <p className="p-5">
                                                        <span className="text-gray-800 fw-bolder text-white">
                                                            Attachments
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <a href={ticketView.image} className="btn btn-success">Download</a>
                                                    </p>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                ticketView.comments == '' ? <></> :
                                    <>
                                        {
                                            ticketView.comments.map((comments: any, intex: any) => (
                                                <div className="col-xl-12">
                                                    <div className="card card-xl-stretch">
                                                        <div className="card-header border-0 py-5">
                                                            <div className='d-flex align-items-center mb-5'>
                                                                <div className='d-flex align-items-center flex-grow-1'>
                                                                    <div className='symbol symbol-45px me-5'>
                                                                        <img src={toAbsoluteUrl(comments?.photo_url)} alt='' />
                                                                    </div>
                                                                    <div className='d-flex flex-column'>
                                                                        <h3 className="card-title text-gray-800 fw-bolder text-white">
                                                                            {comments?.user.f_name} {comments?.user.l_name}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='my-0 text-hover-primary fw-semibold'>
                                                                {moment(comments.created_at).format('LLLL')}
                                                            </div>
                                                        </div>
                                                        <div className="card-body p-5">
                                                            <p className="p-5">{comments.comment}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                            }
                        </>
                }
            </div>
        </>
    )
}


export default TicketView;
