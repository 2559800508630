/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../_metronic/layout/core'

import { baseUrl, APIURL } from '../../config/config'

import { parseCookies } from 'nookies'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Switch } from '@headlessui/react'

import { BsFillSunFill, BsMoonStarsFill } from 'react-icons/bs'
import Table from 'react-bootstrap/Table'

import CatStatus from '../components/CatStatus'

import Pagination from 'replace-js-pagination'
import BrandStatus from '../components/BrandStatus'

const Brand: FC = () => {
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [conLoading, setConLoading] = useState(false)
  const [edit, setEdite] = useState(false)
  const [mainBrands, setMainBrands] = useState([])

  const [imgLoading, setImgLoading] = useState(false)
  const [logoImg, setLogoImg] = useState('')
  const [cats, setCats] = useState([])
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerpage] = useState(0)
  const [lastPage, setLastPage] = useState(0)

  const fetchBrand = async (page: number) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/brands?page=${page}`, requestOptions)
    const result = await response.json()

    if (result.success == true) {
      setMainBrands(result?.data)

      setTotal(result?.paginate?.total)
      setCurrentPage(result?.paginate?.current_page)
      setPerpage(result?.paginate?.per_page)
      setLastPage(result?.paginate?.last_page)
    }

    const categories = await fetch(`${APIURL}/admin/categories`, requestOptions)
    const catresult = await categories.json()
    if (catresult.success == true) {
      setCats(catresult?.data)
    }

    // console.log('result', result);
  }

  useEffect(() => {
    setLoading(true)
    fetchBrand(1)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [brand, setBrand] = useState({
    category_id: '',
    name: '',
    founding_year: '',
    about: '',
    logo: '',
  })

  const [brandEdit, setBrandEdit] = useState({
    id: '',
    category_id: '',
    name: '',
    founding_year: '',
    about: '',
    logo: '',
  })

  const updateBrand = (e: { target: { name: any; value: any } }) => {
    setBrand({ ...brand, [e.target.name]: e.target.value })
  }
  const editBrand = (e: { target: { name: any; value: any } }) => {
    setBrandEdit({ ...brandEdit, [e.target.name]: e.target.value })
  }

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      let fileType = file.type;
      if (fileType.match(/image/g) == "image") {
        let databs64 = `data:${fileType};base64,`;

        //@ts-ignore
        let imageFile = reader.result.replace(String(databs64), '');

        //@ts-ignore
        setLogoImg(reader.result)

        setBrand({
          ...brand,
          [name]: imageFile,
        })

        setBrandEdit({
          ...brandEdit,
          [name]: imageFile,
        })

        setImgLoading(false)
      } else {

        toast.error('Please select image file')
        setImgLoading(false)
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  async function SubmitHandler(e: any) {
    e.preventDefault()
    setConLoading(true)

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'POST',
      body: JSON.stringify({
        ...brand,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/brand`, requestOptions)
    const result = await response.json()

    // console.log("response.status: ", response);
    if (response.status == 200) {
      if (result.success == true) {
        toast.success(result.message)
        fetchBrand(1)
        setBrand({
          ...brand,
          category_id: '',
          name: '',
          founding_year: '',
          about: '',
          logo: '',
        })
        setLogoImg('')
      }
    } else if (response.status == 415) {
      // console.log("message", result);
      if (result?.message?.name?.length > 0) {
        toast.error(result?.message?.name[0])
      }

      if (result?.message?.points?.length > 0) {
        toast.error(result?.message?.points[0])
      }
    }

    setConLoading(false)
  }

  async function editData(id: any) {
    var myHeaders = new Headers()

    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/brand?id=${id}`, requestOptions)
    // console.log("response.status: ", response);
    const result = await response.json()
    if (response.status == 200) {
      if (result.success == true) {
        let BrandData = {
          id: result.data.id,
          category_id: result.data.category_id,
          name: result.data.brand_name,
          founding_year: result.data.founding_year,
          about: result.data.about,
          logo: '',
        }

        setLogoImg(result.data.logo_path)
        setBrandEdit(BrandData)
        setEdite(true)
      }
    }
  }

  async function EditHandler(e: any) {
    e.preventDefault()
    setConLoading(true)
    var myHeaders = new Headers()

    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'PUT',
      body: JSON.stringify({
        ...brandEdit,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/brand`, requestOptions)
    // console.log("response.status: ", response);
    const result = await response.json()
    if (response.status == 200) {
      if (result.success == true) {
        toast.success(result.message)
        fetchBrand(1)
        setEdite(false)
      }
    } else if (response.status == 415) {
      // console.log("message", result);
      if (result?.message?.name?.length > 0) {
        toast.error(result.message?.name[0])
      }

      if (result?.message?.points?.length > 0) {
        toast.error(result?.message?.points[0])
      }
    }
    setConLoading(false)
  }

  const [activePage, setActivePage] = useState(1)

  const handlePageChange = (page: number) => {
    setActivePage(page)
    setLoading(true)
    fetchBrand(page)
    setLoading(false)
  }

  // console.log('brand', brand)



  return (
    <>
      <PageTitle>Brand</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {
          edit ?
            <>
              <div className='col-xl-8'>
                <div className='card card-xl-stretch'>
                  <div className='card-header border-0 bg-success py-5'>
                    <h3 className='card-title fw-bolder text-white'>Brand List</h3>
                  </div>
                  <div className='card-body p-2'>
                    <div className='row mb-4'>
                      <div className='col-sm-12'>
                        <Table striped bordered hover responsive='sm'>
                          <thead>
                            <tr>
                              <th className='fs-4'>Logo</th>
                              <th className='fs-4'>Name</th>
                              <th className='fs-4'>Category</th>
                              <th className='fs-4 text-center'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mainBrands.map((item: any, index: number) => (
                              <tr key={`${item.id}.${index}`} className='odd align-middle'>
                                <td>
                                  <picture className='symbol symbol-45px me-5'>
                                    <img src={item.logo_path} alt='' />
                                  </picture>
                                </td>
                                <td>{item.brand_name}</td>
                                <td>{item.category_name}</td>
                                <td className="d-flex justify-content-center">
                                  <span className='mr-2 h-8 flex items-center'>
                                    <BrandStatus id={item.id} status={item.status} />
                                  </span>
                                  <button className='btn btn-success' onClick={() => editData(item.id)}>
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className='col-sm-12'></div>
                    </div>
                  </div>
                  <div className='card-footer p-4 mb-2 bg-secondary'>
                    <div className='d-flex flex-stack flex-wrap'>
                      <div className='fs-6 fw-bold text-gray-700'>
                        Showing {currentPage} to 10 of {total} entries
                      </div>
                      {/*begin::Pages*/}
                      <div className='pagination'>
                        <Pagination
                          hideFirstLastPages={false}
                          hideNavigation={true}
                          linkClassFirst='next'
                          linkClassPrev='previous'
                          itemClass='page-item'
                          linkClass='page-link'
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={total}
                          pageRangeDisplayed={10}
                          onChange={handlePageChange}
                        />
                      </div>
                      {/*end::Pages*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4'>
                <div className='card card-xl-stretch'>
                  <div className='card-header border-0 bg-info py-5'>
                    <h3 className='card-title fw-bolder text-white'>
                      {edit ? 'Update Brand' : 'New Brand'}
                    </h3>
                  </div>
                  <div className='card-body p-2'>
                    <form className='p-4 z-40' onSubmit={EditHandler}>
                      <input type='hidden' name='id' value={brandEdit.id} />

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Category</label>
                        <select
                          name='category_id'
                          onChange={editBrand}
                          value={brandEdit?.category_id}
                          className='form-control form-control-lg form-control-solid'
                        >
                          <option value='' selected>
                            Choose Category...
                          </option>
                          {cats.map((item: any, index: any) => (
                            <option
                              key={`${item}.${index}`}
                              value={item.id}
                              //@ts-ignore
                              selected={brandEdit?.category_id == item.id ? `selected` : `false`}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='text'
                          value={brandEdit.name}
                          name='name'
                          onChange={editBrand}
                          placeholder='Name'
                          autoComplete='off'
                        />
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Founding Year</label>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='text'
                          value={brandEdit.founding_year}
                          name='founding_year'
                          onChange={editBrand}
                          placeholder='Founding Year'
                          autoComplete='off'
                        />
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>About</label>
                        <textarea
                          defaultValue=''
                          name='about'
                          value={brandEdit.about}
                          onChange={editBrand}
                          className='form-control form-control-lg form-control-solid'
                          rows={4}
                          cols={40}
                        />
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Logo</label>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='file'
                          name='logo'
                          onChange={(e) => uploadFile(e)}
                        />
                        <picture className='mt-2 mb-2'>
                          {imgLoading ? (
                            <>
                              <div
                                className='d-flex justify-content-center img-fluid form-control mt-2 mb-2'
                                style={{ width: '150px', height: '100px' }}
                              >
                                <div className='spinner-border' role='status'>
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {logoImg == '' ? (
                                <>
                                  <img
                                    src={`${baseUrl}/assets/img/thum.jpg`}
                                    className='img-fluid form-control mt-2 mb-2'
                                    style={{ width: '150px', height: '100px' }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={`${logoImg}`}
                                    className='img-fluid form-control mt-2 mb-2'
                                    style={{ width: '150px', height: '100px' }}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </picture>
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>
                      <div>
                        {conLoading ? (
                          <>
                            <button className='btn btn-lg btn-primary w-100 mb-5' disabled>
                              <div className='spinner-border' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                              </div>
                            </button>
                          </>
                        ) : (
                          <>
                            <button className='btn btn-lg btn-primary w-100 mb-5'>Update</button>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </> : <>
              <div className='col-xl-8'>
                <div className='card card-xl-stretch'>
                  <div className='card-header border-0 bg-success py-5'>
                    <h3 className='card-title fw-bolder text-white'>Brand List</h3>
                  </div>
                  <div className='card-body p-2'>
                    <div className='row mb-4'>
                      <div className='col-sm-12'>
                        <Table striped bordered hover responsive='sm'>
                          <thead>
                            <tr>
                              <th className='fs-4'>Logo</th>
                              <th className='fs-4'>Name</th>
                              <th className='fs-4'>Category</th>
                              <th className='fs-4 text-center'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mainBrands.map((item: any, index: number) => (
                              <tr key={`${item.id}.${index}`} className='odd align-middle'>
                                <td>
                                  <picture className='symbol symbol-45px me-5'>
                                    <img src={item.logo_path} alt='' />
                                  </picture>
                                </td>
                                <td>{item.brand_name}</td>
                                <td>{item.category_name}</td>
                                <td className="d-flex justify-content-center">
                                  <span className='mr-2 h-8 flex items-center'>
                                    <BrandStatus id={item.id} status={item.status} />
                                  </span>
                                  <button className='btn btn-success' onClick={() => editData(item.id)}>
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className='col-sm-12'></div>
                    </div>
                  </div>
                  <div className='card-footer p-4 mb-2 bg-secondary'>
                    <div className='d-flex flex-stack flex-wrap'>
                      <div className='fs-6 fw-bold text-gray-700'>
                        Showing {currentPage} to 10 of {total} entries
                      </div>
                      {/*begin::Pages*/}
                      <div className='pagination'>
                        <Pagination
                          hideFirstLastPages={false}
                          hideNavigation={true}
                          linkClassFirst='next'
                          linkClassPrev='previous'
                          itemClass='page-item'
                          linkClass='page-link'
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={total}
                          pageRangeDisplayed={10}
                          onChange={handlePageChange}
                        />
                      </div>
                      {/*end::Pages*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4'>
                <div className='card card-xl-stretch'>
                  <div className='card-header border-0 bg-info py-5'>
                    <h3 className='card-title fw-bolder text-white'>
                      {edit ? 'Update Brand' : 'New Brand'}
                    </h3>
                  </div>
                  <div className='card-body p-2'>
                    <form className='p-4 z-40' onSubmit={SubmitHandler}>
                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Category</label>
                        <select
                          name='category_id'
                          onChange={updateBrand}
                          className='form-control form-control-lg form-control-solid'
                        >
                          <option value='' selected>
                            Choose Category...
                          </option>
                          {cats.map((item: any, index: any) => (
                            <option key={`${item}.${index}`} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='text'
                          value={brand.name}
                          name='name'
                          onChange={updateBrand}
                          placeholder='Name'
                          autoComplete='off'
                        />
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Founding Year</label>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='text'
                          value={brand.founding_year}
                          name='founding_year'
                          onChange={updateBrand}
                          placeholder='Year'
                          autoComplete='off'
                        />
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>About</label>
                        <textarea
                          defaultValue=''
                          name='about'
                          value={brand.about}
                          onChange={updateBrand}
                          className='form-control form-control-lg form-control-solid'
                          rows={4}
                          cols={40}
                        />
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Logo</label>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='file'
                          name='logo'
                          onChange={(e) => uploadFile(e)}
                        />
                        <picture className='mt-2 mb-2'>
                          {imgLoading ? (
                            <>
                              <div
                                className='d-flex justify-content-center img-fluid form-control mt-2 mb-2'
                                style={{ width: '150px', height: '100px' }}
                              >
                                <div className='spinner-border' role='status'>
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {logoImg == '' ? (
                                <>
                                  <img
                                    src={`${baseUrl}/assets/img/thum.jpg`}
                                    className='img-fluid form-control mt-2 mb-2'
                                    style={{ width: '150px', height: '100px' }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={`${logoImg}`}
                                    className='img-fluid form-control mt-2 mb-2'
                                    style={{ width: '150px', height: '100px' }}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </picture>
                        <div className='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div>
                        {conLoading ? (
                          <>
                            <button className='btn btn-lg btn-primary w-100 mb-5' disabled>
                              <div className='spinner-border' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                              </div>
                            </button>
                          </>
                        ) : (
                          <>
                            <button className='btn btn-lg btn-primary w-100 mb-5'>Submit</button>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
        }
      </div>
      {/* end::Row */}
    </>
  )
}

export default Brand
