
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getDatabase, ref, set, get, onValue, child, push, update } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCA_qwuwO_jEdkLZ2m3McMNHprLl5zbgIc",
    authDomain: "elit-list.firebaseapp.com",
    databaseURL: "https://elit-list-default-rtdb.firebaseio.com",
    projectId: "elit-list",
    storageBucket: "elit-list.appspot.com",
    messagingSenderId: "625350610122",
    appId: "1:625350610122:web:6dea6d806eb30980b37833"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const FirebaseDB = getFirestore(app)

export { FirebaseDB, getDatabase, ref, set, get, onValue, child, push, update };
