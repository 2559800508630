import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { parseCookies } from "nookies";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Form from 'react-bootstrap/Form';


const API_URL = process.env.REACT_APP_API_URL

interface StatusProps {
  id?: number;
  status?: number;
  token?: string;
}

/**
 * An input element
 */
const CamStatus: React.FC<StatusProps> = (props: StatusProps) => {
  const { id, status } = props;

  console.log("id", id, "status ", status);

  const cookies = parseCookies();
  const token = cookies?.token;

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (status == 1) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, []);

  async function StatusHandler(data: any) {

    setEnabled(data);

    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${API_URL}/merchant/campaign-status?id=${id}`,
      requestOptions
    );
    const result = await response.json();
    if (response.status == 200) {
      if (result.success == true) {
        toast.success(result.message);
      }
    }
  }

  return (
    <Form>
      {
        enabled ? <><Form.Check
          checked={enabled}
          onClick={() => StatusHandler(false)}
          type="switch"
          id="custom-switch"
          className="mt-4"
        /></> : <>
          <Form.Check
            onClick={() => StatusHandler(true)}
            type="switch"
            id="custom-switch"
            className="mt-4"
          /></>
      }

    </Form>
  );
};

export default CamStatus;
