/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'

import { baseUrl, APIURL } from '../../../config/config'
import { PageTitle } from '../../../_metronic/layout/core'
import { useParams } from 'react-router-dom'
import { parseCookies } from 'nookies'
import { Button, Dropdown } from 'react-bootstrap'
import moment from 'moment'
import ReviewDialog from './ReviewDialog'
import { FaDownload } from 'react-icons/fa'

import * as XLSX from 'xlsx'

import ChatBox from '../../components/ChatBox'
import ViewStats from './ViewStats'
import { useAuth } from '../../modules/auth'

const BookingList = () => {
  const { currentUser, setCurrentUser } = useAuth()

  const [modalShow, setModalShow] = useState(false)
  const [viewStats, setViewStats] = useState(false)
  const params = useParams()
  const { id } = params
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [conLoading, setConLoading] = useState(false)
  const [bookingList, setBookingList] = useState([])
  const [sortedData, setSortedData] = useState<any[]>([])
  const [report, setReport] = useState<any[]>([])
  const [bookingListInfo, setBookingListInfo] = useState<any>()
  const [rule, setRule] = useState({
    subscription_id: id,
    key: '',
    name: '',
    status: 1,
  })

  const fetchBooking = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(
      `${APIURL}/merchant/booking-by-campaign?cam_id=${id}`,
      requestOptions
    )
    const result = await response.json()
    if (result.success == true) {
      let newData: any[] = []

      result?.data?.forEach((item: any) => {
        newData.push({
          Booking_date: moment(item?.booking_date).format('Do, MMMM YYYY h:mm:ss a'),
          Influencer_Name: `${item?.influencer?.f_name} ${item?.influencer?.l_name}`,
          Influencer_Email: item?.influencer?.email,
          Status: item?.status,
        })
      })

      setReport(newData)
      setBookingList(result?.data)
      setSortedData(result?.data)
    }
  }

  useEffect(() => {
    // console.log('id', id);
    setLoading(true)
    fetchBooking()
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let cuser = cookies?.user
    let luser
    if (cuser) {
      luser = JSON.parse(cuser)
    } else {
      luser = undefined
    }
    setCurrentUser(luser)
  }, [cookies?.user])

  const ApproveBooking = async (bid: any) => {
    setLoading(true)
    var myHeaders = new Headers()

    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      booking_id: bid,
    })

    var requestOptions: any = {
      method: 'POST',
      body: raw,
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/approve-booking`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      fetchBooking()
    }
  }

  const RejectBooking = async (bid: any) => {
    setLoading(true)
    var myHeaders = new Headers()

    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      booking_id: bid,
    })

    var requestOptions: any = {
      method: 'POST',
      body: raw,
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/reject-booking`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      fetchBooking()
    }
  }

  const openRatingModal = (info: any) => {
    setBookingListInfo(info)
    setModalShow(true)
  }

  const openViewStatsModal = (info: any) => {
    setBookingListInfo(info)
    setViewStats(true)
  }

  const hendelStatus = (status: any) => { }

  const generateExcelFile = () => {
    // make keys as headers for the excel sheet
    const headers = Object.keys(report[0])
    // make this headers as a capitalised format
    const titles = headers?.map((title) => title?.split('_').join(' ').toUpperCase())
    // make values as values for the excel sheet
    const values = report.map((item: any) => Object.values(item))
    // make a new array with headers and values
    const data = [titles, ...values]
    // make a new workbook
    const wb = XLSX.utils.book_new()
    // make a new sheet name
    const ws = XLSX.utils.aoa_to_sheet(data)
    // add the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')

    const date = moment().format('DD-MM-YYYY')
    const time = moment().format('HH:mm:ss')
    const dateTime = date + '_' + time
    const fileName = 'Booking_List_' + dateTime + '.xlsx'

    // export the workbook
    XLSX.writeFile(wb, fileName)
  }

  const [sort, setSort] = useState('all')

  const sortData = (field: any) => {
    if (field === 'all') {
      setSort('all')
      setSortedData(bookingList)
    } else if (field === 'pending') {
      setSort('pending')
      const sorted = bookingList.filter((item: any) => item.status == 0)
      setSortedData(sorted)
    } else if (field === 'approved') {
      setSort('approved')
      const sorted = bookingList.filter((item: any) => item.status == 1)
      setSortedData(sorted)
    } else if (field === 'rejected') {
      setSort('rejected')
      const sorted = bookingList.filter((item: any) => item.status == 2)
      setSortedData(sorted)
    } else if (field === 'completed') {
      setSort('completed')
      const sorted = bookingList.filter((item: any) => item.status == 3)
      setSortedData(sorted)
    }
  }

  return (
    <>
      <ReviewDialog
        show={modalShow}
        onHide={() => setModalShow(false)}
        bookingListInfo={bookingListInfo}
      />
      <ViewStats
        show={viewStats}
        onHide={() => setViewStats(false)}
        bookingListInfo={bookingListInfo}
      />

      <PageTitle>Booking List</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-success py-5 flex align-items-center justify-content-between'>
              <h3 className='card-title fw-bolder text-white'>Booked List</h3>

              {sortedData.length !== 0 && (
                <FaDownload
                  className='h3'
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => generateExcelFile()}
                />
              )}
            </div>
            <div className='card-body p-2'>
              <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                  <thead>
                    <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                      <th>Booking Date</th>
                      <th>Influencer Name</th>
                      <th>Influencer Email</th>
                      <th className='d-flex align-items-center'>
                        Status{' '}
                        <div className='dropdown'>
                          <div
                            className='dropdown-toggle'
                            id='dropdownMenuButton2'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '10px',
                              }}
                            >
                              {sort}
                            </span>
                          </div>
                          <ul
                            className='dropdown-menu dropdown-menu-dark'
                            aria-labelledby='dropdownMenuButton2'
                          >
                            {['all', 'pending', 'approved', 'rejected', 'completed'].map(
                              (item: any, index: any) => (
                                <li key={`${item.id}.${index}`}>
                                  <a
                                    className='dropdown-item'
                                    href='#'
                                    onClick={() => sortData(item)}
                                  >
                                    {item}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </th>
                      <th className='min-w-200px  sorting_disabled text-center'>Action</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold'>
                    {sortedData.length === 0 && (
                      <tr>
                        <td colSpan={6} className='text-center'>
                          No Data Found
                        </td>
                      </tr>
                    )}

                    {sortedData?.map((item: any, index: any) => (
                      <tr
                        key={`${item.id}.${index}`}
                        className='text-start text-dark fw-bolder fs-7  gs-0 mb-5'
                      >
                        <td>{moment(item.booking_date).format('Do, MMMM YYYY h:mm:ss a')}</td>
                        <td>
                          {item.influencer.f_name} {item.influencer.l_name}
                        </td>
                        <td>{item.influencer.email}</td>
                        <td>
                          {item.status == 0 ? (
                            <span className='btn btn-warning btn-sm'>Pending</span>
                          ) : (
                            <></>
                          )}
                          {item.status == 1 ? (
                            <span className='btn btn-success btn-sm'>Approve</span>
                          ) : (
                            <></>
                          )}
                          {item.status == 2 ? (
                            <span className='btn btn-danger btn-sm'>Rejected</span>
                          ) : (
                            <></>
                          )}
                          {item.status == 3 ? (
                            <span className='btn btn-info btn-sm'>Complete</span>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className='d-flex mx-auto gap-3 justify-content-center align-items-center'>
                            {item.status == 0 ? (
                              <Dropdown>
                                <Dropdown.Toggle variant='success' id='dropdown-basic' size='sm'>
                                  Action
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => ApproveBooking(item.id)}>
                                    Approve
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => RejectBooking(item.id)}>
                                    Rejected
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              <>
                                {/* <Button variant='success' size='sm' disabled>
                                Action
                              </Button> */}

                                {item.status == 3 ? (
                                  <>
                                    <Button
                                      variant='success'
                                      size='sm'
                                      onClick={() => openRatingModal(item)}
                                    >
                                      Add Review
                                    </Button>
                                    <Button
                                      variant='primary'
                                      size='sm'
                                      style={{
                                        marginLeft: '5px',
                                      }}
                                      onClick={() => openViewStatsModal(item)}
                                    >
                                      view
                                    </Button>
                                  </>
                                ) : (
                                  <Button variant='success' size='sm' disabled>
                                    Action
                                  </Button>
                                )}
                              </>
                            )}

                            {['4'].includes(currentUser?.user_type as string) && (
                              <ChatBox influencer={item.influencer} campaign={item.campaign} />
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='w-100 mb-5' style={{ height: '100px' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default BookingList
