/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'

import { baseUrl, APIURL } from '../../../config/config'
import { parseCookies } from 'nookies'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'

const OpenTicket: FC = () => {
  const navigate = useNavigate()
  const cookies = parseCookies()
  const token = cookies?.token
  const user = JSON.parse(cookies?.user)

  const [loading, setLoading] = useState(true)
  const [conLoading, setConLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [logoImg, setLogoImg] = useState('')
  const [userInfo, setUserInfo] = useState([])
  const [cats, setCats] = useState([])
  const [ticket, setTicket] = useState({
    ticket_user: '',
    issue_subject: '',
    issue_text: '',
    ticket_category: '',
    image: '',
  })

  const fetchCats = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const getUser = await fetch(`${APIURL}/me`, requestOptions)
    const userData = await getUser.json()
    console.log('userData', userData)

    if (userData.success == true) {
      setUserInfo(userData?.data?.user)
      setTicket({ ...ticket, ticket_user: userData?.data?.user?.id })
    }

    const categories = await fetch(`${APIURL}/admin/ticket-categories`, requestOptions)
    const catresult = await categories.json()
    if (catresult.success == true) {
      setCats(catresult?.data)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchCats()
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTicket = (e: { target: { name: any; value: any } }) => {
    setTicket({ ...ticket, [e.target.name]: e.target.value })
  }

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      // let search = reader.result.search("data:image/jpeg;base64,", "")
      let imageFile

      //@ts-ignore
      if (reader.result.search('data:image/jpeg;base64,') == 0) {
        //@ts-ignore
        imageFile = reader.result.replace('data:image/jpeg;base64,', '')
        //@ts-ignore
      } else if (reader.result.search('data:image/png;base64,') == 0) {
        //@ts-ignore
        imageFile = reader.result.replace('data:image/png;base64,', '')
      }

      //@ts-ignore
      setLogoImg(reader.result)

      console.log('imageFile', imageFile)

      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)

      const formData = new FormData()
      formData.append('file', imageFile)
      formData.append('directory_name', 'tutorial')
      formData.append('type', 'image')

      const requestOptions: any = {
        method: 'POST',
        body: formData,
        headers: myHeaders,
        redirect: 'follow',
      }

      const response = await fetch(`${APIURL}/admin/upload-file`, requestOptions)
      const result = await response.json()
      console.log('result', result)
      if (result?.success) {
        // return result?.data?.file_url
        setTicket({
          ...ticket,
          [name]: result?.data?.file_url,
        })
      }

      setImgLoading(false)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  async function SubmitHandler(e: any) {
    e.preventDefault()
    setConLoading(true)

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'POST',
      body: JSON.stringify({
        ...ticket,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/ticket-create`, requestOptions)
    const result = await response.json()

    // console.log("response.status: ", response);
    if (response.status == 200) {
      if (result.success == true) {
        toast.success(result.message)
        navigate('/support/tickets')
      }
    } else if (response.status == 415) {
      // console.log("message", result);
      if (result?.message?.name?.length > 0) {
        toast.error(result?.message?.name[0])
      }

      if (result?.message?.points?.length > 0) {
        toast.error(result?.message?.points[0])
      }
    }

    setConLoading(false)
  }

  // console.log('brand', brand)

  return (
    <>
      <PageTitle>Open Ticket</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-info py-5'>
              <h3 className='card-title fw-bolder text-white'>Open Ticket</h3>
            </div>
            <div className='card-body p-2'>
              <form className='p-4 z-40' onSubmit={SubmitHandler}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        value={user.fullname}
                        autoComplete='off'
                        readOnly
                        disabled
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Email Address</label>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        value={user.email}
                        autoComplete='off'
                        readOnly
                        disabled
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Category</label>
                  <select
                    name='ticket_category'
                    onChange={updateTicket}
                    className='form-control form-control-lg form-control-solid'
                  >
                    <option value='' selected>
                      Choose Category...
                    </option>
                    {cats.map((item: any, index: any) => (
                      <option key={`${item}.${index}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Subject</label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    value={ticket.issue_subject}
                    name='issue_subject'
                    onChange={updateTicket}
                    placeholder='Subject'
                    autoComplete='off'
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Message</label>
                  <textarea
                    defaultValue=''
                    name='issue_text'
                    value={ticket.issue_text}
                    onChange={updateTicket}
                    className='form-control form-control-lg form-control-solid'
                    rows={4}
                    cols={40}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Attachments</label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='file'
                    name='image'
                    onChange={(e) => uploadFile(e)}
                  />
                  <picture className='mt-2 mb-2'>
                    {imgLoading ? (
                      <>
                        <div
                          className='d-flex justify-content-center img-fluid form-control mt-2 mb-2'
                          style={{ width: '150px', height: '100px' }}
                        >
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {logoImg == '' ? (
                          <>
                            <img
                              src={`${baseUrl}/assets/img/thum.jpg`}
                              className='img-fluid form-control mt-2 mb-2'
                              style={{ width: '150px', height: '100px' }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`${logoImg}`}
                              className='img-fluid form-control mt-2 mb-2'
                              style={{ width: '150px', height: '100px' }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </picture>
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <div className='row mb-4'>
                  {conLoading ? (
                    <>
                      <button
                        className='btn btn-lg btn-primary mb-5'
                        disabled
                        style={{ width: '180px' }}
                      >
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </button>
                    </>
                  ) : (
                    <>
                      <button className='btn btn-lg btn-primary mb-5' style={{ width: '180px' }}>
                        Submit
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default OpenTicket
