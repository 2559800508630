/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, FormEvent, useRef } from "react";

import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';

// import it first.
import en_US from 'timeago.js/lib/lang/en_US';
import clsx from 'clsx'

import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
  KTSVG,
} from '../../../helpers'

// import { KTSVG } from '../../../helpers'

import { useAuth } from "../../../../app/modules/auth";



import { FirebaseDB, getDatabase, ref, set, get, onValue, child, push, update } from '../../../../config/firebase'

import { ChatInner } from '../../../partials/chat/ChatInner'

import { Switch } from "@headlessui/react";
import { parseCookies } from "nookies";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";

import { baseUrl, APIURL } from '../../../../config/config'
import { hide } from "@popperjs/core";
const API_URL = process.env.REACT_APP_API_URL

const db = getDatabase();
const dbRef = ref(getDatabase());



const SidebarFooter = () => {

  const { currentUser } = useAuth();
  const cookies = parseCookies();
  const token = cookies?.token;

  // console.log('currentUser', currentUser);

  const isDrawer = true;
  const timestamp = Date.now();
  const [chatOpen, setChatOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [myAccount, setMyAccount] = useState<any>([]);
  const [chatData, setChatData] = useState<any>([])
  const [loadingMSG, setLoadingMSG] = useState(false);
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState([] as any)

  const messagesEndRef = useRef<null | HTMLElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const updateMessage = (e: { target: { name: any; value: any } }) => {
    setMessage(e.target.value);
  };

  const getMyAccount = async () => {
    setLoading(true);
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(
      `${APIURL}/me`,
      requestOptions
    )
    const result = await response.json()
    if (result.success === true) {
      setMyAccount(result?.data)
    }
    setLoading(false);
  }

  useEffect(() => {
    getMyAccount();
  }, [])

  const getMessages = () => {
    get(child(dbRef, `support_chats/${myAccount?.user?.id}_admin`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setChatData(data)
      } else {
        setChatData([])
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  get(child(dbRef, `support_chats/${myAccount?.user?.id}_admin/messages`)).then((snapshot) => {
    if (snapshot.exists()) {
      setLoadingMSG(false);
      // const data = snapshot.val();
      // console.log('exists messages', data)
      let newMsg: any = []
      snapshot.forEach(function (item: any) {
        var itemVal = item.val();
        newMsg.push(
          {
            account_manger_id: itemVal.account_manger_id,
            account_manger_name: itemVal.account_manger_name,
            account_manager_profile: itemVal.account_manager_profile,
            influencer_id: itemVal.influencer_id,
            influencer_name: itemVal.influencer_name,
            influencer_profile: itemVal.influencer_profile,
            admin_id: itemVal.admin_id,
            admin_name: itemVal.admin_name,
            admin_profile: itemVal.admin_profile,
            message: itemVal.message,
            timestamp: itemVal.timestamp,
            user_type: itemVal.user_type,


          });
      });
      setMessages(newMsg)
      setLoadingMSG(true);
    } else {
      // console.log('not exists messages')
      setLoadingMSG(false);
      setMessages([])
    }
  }).catch((error) => {
    console.error(error);
  });


  const NewChatPost = (postData: any) => {
    set(ref(db, `/support_chats/${postData.channel}`), { ...postData })
      .then(() => {
        getMessages();
      })
      .catch((error) => {
        getMessages();
      });
  }

  const MessageSend = (channel: any, postData: any) => {
    const db = getDatabase();
    const newPostKey = push(child(ref(db), 'support_chats')).key;
    set(ref(db, `/support_chats/${channel}/messages/${newPostKey}`), { ...postData })
      .then(() => {
        getMessages();
      })
      .catch((error) => {
        getMessages();
      });
    scrollToBottom()
  }

  const handleOpen = () => {
    setLoading(true)
    if (chatOpen === true) {
      setChatOpen(false);
    } else {
      setChatOpen(true);

      const postData = {
        influencer_id: '',
        influencer_name: '',
        influencer_profile: '',
        admin_id: '',
        admin_name: '',
        admin_profile: '',
        channel: `${myAccount?.user?.id}_admin`,
        account_manger_id: `${myAccount?.user?.id}`,
        account_manger_name: `${myAccount?.user?.f_name} ${myAccount?.user?.l_name}`,
        account_manager_profile: `${myAccount?.user?.profile_photo == null ? 'https://elitelist.spacecats.tech/media/avatars/300-1.jpg' : myAccount?.user?.profile_photo}`,
        user_type: "account_manager",
        timestamp: timestamp,
      };

      get(child(dbRef, `support_chats/${myAccount?.user?.id}_admin`)).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          getMessages();
        } else {
          NewChatPost(postData);
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    setLoading(false)
  }

  function SubmitHandler(e: FormEvent) {
    e.preventDefault();
    // console.log(e);
    const channel = `${myAccount?.user?.id}_admin`;

    const postData = {
      admin_id: '',
      admin_name: '',
      admin_profile: '',
      influencer_id: '',
      influencer_name: '',
      influencer_profile: '',
      account_manger_id: `${myAccount?.user?.id}`,
      account_manger_name: `${myAccount?.user?.f_name} ${myAccount?.user?.l_name}`,
      account_manager_profile: `${myAccount?.user?.profile_photo == null ? 'https://elitelist.spacecats.tech/media/avatars/300-1.jpg' : myAccount?.user?.profile_photo}`,
      message: message,
      timestamp: timestamp,
      user_type: "account_manager",
    };

    MessageSend(channel, postData);
    setMessage('')
  }


  // console.log('messages', messages);
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <span
        className='btn btn-flex flex-center btn-custom btn-dark overflow-hidden text-nowrap px-0 h-40px' onClick={handleOpen}
        style={{
          width: '110px'
        }}
        data-bs-toggle="tooltip" title="Live chat for admin support"
      >
        <span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="currentColor"></path><rect x="6" y="12" width="7" height="2" rx="1" fill="currentColor"></rect><rect x="6" y="7" width="12" height="2" rx="1" fill="currentColor"></rect></svg></span>
        <span className='btn-label'>Live Chat</span>
      </span>

      <div
        id='kt_drawer_chat'
        className={`bg-body ${chatOpen ? `drawer drawer-end drawer-on` : ``}`}
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
      >
        <div style={{ zIndex: '109' }} className="drawer-overlay" onClick={handleOpen}></div>
        <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger' style={{ zIndex: '10000', height: '100vh' }}>
          <div className='card-header pe-5' id='kt_drawer_chat_messenger_header' style={{ height: '10vh' }}>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                  {
                    chatData?.account_manger_name
                  }
                </a>

                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>Active</span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='btn btn-sm btn-icon btn-active-light-primary' onClick={handleOpen}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </div>
            </div>
          </div>

          <div
            className='card-body'
            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
            style={{ height: '80vh', overflow: "auto", overflowX: 'scroll' }}
          >
            <div
              className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies={
                isDrawer
                  ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                  : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              }
              data-kt-scroll-wrappers={
                isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
              }
              data-kt-scroll-offset={isDrawer ? '0px' : '5px'}

              style={{ height: '66vh' }}
            >
              {loadingMSG ? <>
                {
                  messages?.map((message: any, index: any) => {
                    const state = message.user_type === 'account_manager' ? 'info' : 'primary'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.user_type}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.user_type === 'account_manager' ? 'start' : 'end'
                      } mb-10`
                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${message.user_type === 'account_manager' ? 'start' : 'end'}`
                          )}

                          data-kt-element={`${message.user_type === 'account_manager' ? 'template-out' : 'template-in'}`}
                          //@ts-ignore
                          ref={messagesEndRef}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.user_type === 'account_manager' ? (
                              <>
                                <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={message.account_manager_profile} />
                                </div>
                                <div className='ms-3'>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                  >
                                    {message.account_manger_name}
                                  </a>
                                  <span className='text-muted fs-7 mb-1'>
                                    <TimeAgo
                                      datetime={message.timestamp}
                                      locale='en_US'
                                    />
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>
                                    <TimeAgo
                                      datetime={message.timestamp}
                                      locale='en_US'
                                    />
                                  </span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >
                                    {message.admin_name}
                                  </a>
                                </div>
                                <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={message.admin_profile} />
                                </div>
                              </>
                            )}
                          </div>

                          <div
                            className={clsx(
                              'p-5 rounded',
                              `bg-light-${state}`,
                              'text-dark fw-bold mw-lg-400px',
                              `text-${message.user_type === 'account_manager' ? 'start' : 'end'}`
                            )}
                            data-kt-element='message-text'
                            dangerouslySetInnerHTML={{ __html: message.message }}
                          ></div>
                        </div>
                      </div>
                    )
                  })

                }
              </> : <></>}



            </div>

            <div
              className='card-footer pt-4'
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}

              style={{ height: '20vh', padding: '0px 0px' }}
            >
              <form className="p-6" onSubmit={SubmitHandler}>
                <textarea
                  className='form-control mb-3'
                  rows={1}
                  data-kt-element='input'
                  placeholder='Type a message'
                  name="message"
                  value={message}
                  onChange={updateMessage}
                  required
                ></textarea>

                <div className='d-flex flex-stack'>
                  {/* <div className='d-flex align-items-center me-2'>
                                    <button
                                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                        type='button'
                                        data-bs-toggle='tooltip'
                                        title='Coming soon'
                                    >
                                        <i className='bi bi-paperclip fs-3'></i>
                                    </button>
                                    <button
                                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                        type='button'
                                        data-bs-toggle='tooltip'
                                        title='Coming soon'
                                    >
                                        <i className='bi bi-upload fs-3'></i>
                                    </button>
                                </div> */}
                  <button
                    className='btn btn-primary'
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SidebarFooter }
