/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth/core/Auth';

const API_URL = process.env.REACT_APP_API_URL

const HeaderNotificationsMenu: FC = () => {

  const { auth } = useAuth()

  const token = auth?.api_token;
  const [Loading, setLoading] = useState(true);
  const [open, setOpen] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const notifications = await fetch(
      `${API_URL}/admin/notification/my-notifications`,
      requestOptions
    );

    if (notifications.status == 200) {
      const result = await notifications.json();
      // console.log('result :-', result);
      setNotifications(result.data);
    } else {
      setNotifications([]);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications
          {/* <span className='fs-8 opacity-75 ps-3'>24 reports</span> */}
        </h3>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {/* {defaultAlerts.map((alert, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                      {' '}
                      <KTSVG
                        path={`/media/${alert.icon}`}
                        className={`svg-icon-2 svg-icon-${alert.state}`}
                      />
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      {alert.title}
                    </a>
                    <div className='text-gray-400 fs-7'>{alert.description}</div>
                  </div>
                </div>

                <span className='badge badge-light fs-8'>{alert.time}</span>
              </div>
            ))} */}


            {notifications.map((item: any, index: any) => (
              <div key={`${item.id}.${index}`} className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center">
                  <div className="mb-0 me-2">
                    <a
                      href="#"
                      className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                    >
                      {item.title}
                    </a>
                    <div className="text-gray-400 fs-7">
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className='py-3 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div> */}
        </div>

      </div>
    </div>
  );
};

export { HeaderNotificationsMenu }
