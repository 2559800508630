/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, FormEvent, useRef } from 'react'

import TimeAgo from 'timeago-react'
import * as timeago from 'timeago.js'

// import it first.
import en_US from 'timeago.js/lib/lang/en_US'

import clsx from 'clsx'

import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../_metronic/helpers'

// import { useAuth } from '../core/Auth'
import { useAuth } from '../modules/auth/core/Auth'

import {
  FirebaseDB,
  getDatabase,
  ref,
  set,
  get,
  onValue,
  child,
  push,
  update,
} from '../../config/firebase'

import { KTSVG } from '../../_metronic/helpers'
import { ChatInner } from '../../_metronic/partials/chat/ChatInner'

import { Switch } from '@headlessui/react'
import { parseCookies } from 'nookies'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'

import { baseUrl, APIURL } from '../../config/config'
import { hide } from '@popperjs/core'
const API_URL = process.env.REACT_APP_API_URL

const db = getDatabase()
const dbRef = ref(getDatabase())

const bufferMessages = defaultMessages

interface ChatBoxPropos {
  influencer: any
  campaign: any
}

const ChatBox: React.FC<ChatBoxPropos> = (props: ChatBoxPropos) => {
  const { influencer, campaign } = props

  const { currentUser } = useAuth()
  const cookies = parseCookies()
  const token = cookies?.token

  const isDrawer = true
  const timestamp = Date.now()
  const [chatOpen, setChatOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [myAccount, setMyAccount] = useState<any>([])
  const [chatData, setChatData] = useState<any>([])
  const [loadingMSG, setLoadingMSG] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState([] as any)

  const messagesEndRef = useRef<null | HTMLElement>(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const updateMessage = (e: { target: { name: any; value: any } }) => {
    setMessage(e.target.value)
  }

  const getMyAccount = async () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/me`, requestOptions)
    const result = await response.json()
    if (result.success === true) {
      setMyAccount(result?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getMyAccount()
  }, [])

  // console.log('currentUser', currentUser);
  // console.log('influencer', influencer);
  // console.log('campaign', campaign);

  const getMessages = () => {
    get(child(dbRef, `chats/${influencer.id}_${campaign.merchant_id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          setChatData(data)
        } else {
          setChatData([])
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  get(child(dbRef, `chats/${influencer.id}_${campaign.merchant_id}/messages`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setLoadingMSG(false)
        // const data = snapshot.val();
        // console.log('exists messages', data)
        let newMsg: any = []
        snapshot.forEach(function (item: any) {
          var itemVal = item.val()
          newMsg.push({
            account_manager_profile: itemVal.account_manager_profile,
            account_manger_name: itemVal.account_manger_name,
            influencer_id: itemVal.influencer_id,
            influencer_name: itemVal.influencer_name,
            influencer_profile: itemVal.influencer_profile,
            merchant_id: itemVal.merchant_id,
            message: itemVal.message,
            timestamp: itemVal.timestamp,
            user_type: itemVal.user_type,
          })
        })
        setMessages(newMsg)
        setLoadingMSG(true)
      } else {
        console.log('not exists messages')
        setLoadingMSG(false)
        setMessages([])
      }
    })
    .catch((error) => {
      console.error(error)
    })

  const NewChatPost = (postData: any) => {
    set(ref(db, `/chats/${postData.channel}`), { ...postData })
      .then(() => {
        getMessages()
      })
      .catch((error) => {
        getMessages()
      })
  }

  const MessageSend = (channel: any, postData: any) => {
    const db = getDatabase()
    const newPostKey = push(child(ref(db), 'chats')).key
    set(ref(db, `/chats/${channel}/messages/${newPostKey}`), { ...postData })
      .then(() => {
        getMessages()
      })
      .catch((error) => {
        getMessages()
      })
    scrollToBottom()
  }

  const handleOpen = () => {
    setLoading(true)
    if (chatOpen === true) {
      setChatOpen(false)
    } else {
      setChatOpen(true)
      const postData = {
        account_manager_profile: `${myAccount?.user?.profile_photo == null
          ? 'https://elitelist.spacecats.tech/media/avatars/300-1.jpg'
          : myAccount?.user?.profile_photo
          }`,
        account_manger_name: `${myAccount?.user?.f_name} ${myAccount?.user?.l_name}`,
        channel: `${influencer.id}_${campaign.merchant_id}`,
        influencer_id: influencer.id,
        influencer_name: influencer.full_name,
        merchant_id: campaign.merchant_id,
        timestamp: timestamp,
      }

      get(child(dbRef, `chats/${influencer.id}_${campaign.merchant_id}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            // const data = snapshot.val();
            getMessages()
          } else {
            NewChatPost(postData)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    setLoading(false)
  }

  function SubmitHandler(e: FormEvent) {
    e.preventDefault()
    console.log(e)
    const channel = `${influencer.id}_${campaign.merchant_id}`

    const postData = {
      account_manager_profile: `${myAccount?.user?.profile_photo == null
        ? 'https://elitelist.spacecats.tech/media/avatars/300-1.jpg'
        : myAccount?.user?.profile_photo
        }`,
      account_manger_name: `${myAccount?.user?.f_name} ${myAccount?.user?.l_name}`,
      influencer_id: '',
      influencer_name: '',
      influencer_profile: '',
      merchant_id: campaign.merchant_id,
      message: message,
      timestamp: timestamp,
      user_type: 'account_manager',
    }

    MessageSend(channel, postData)
    setMessage('')
  }

  // console.log('messages', messages);

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
          <Button variant='primary' size='sm' onClick={handleOpen}>
            Chat
          </Button>
        </>
      )}

      <div
        id='kt_drawer_chat'
        className={`bg-body ${chatOpen ? `drawer drawer-end drawer-on` : ``}`}
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
      >
        <div style={{ zIndex: '109' }} className='drawer-overlay' onClick={handleOpen}></div>
        <div
          className='card w-100 rounded-0'
          id='kt_drawer_chat_messenger'
          style={{ zIndex: '10000', height: '100vh' }}
        >
          <div
            className='card-header pe-5'
            id='kt_drawer_chat_messenger_header'
            style={{ height: '10vh' }}
          >
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a
                  href='#'
                  className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                >
                  {chatData?.influencer_name}
                </a>

                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>Active</span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='btn btn-sm btn-icon btn-active-light-primary' onClick={handleOpen}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </div>
            </div>
          </div>

          <div
            className='card-body'
            id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
            style={{ height: '80vh', overflow: 'auto', overflowX: 'scroll' }}
          >
            <div
              className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies={
                isDrawer
                  ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                  : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              }
              data-kt-scroll-wrappers={
                isDrawer
                  ? '#kt_drawer_chat_messenger_body'
                  : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
              }
              data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
              style={{ height: '66vh' }}
            >
              {loadingMSG ? (
                <>
                  {messages?.map((message: any, index: any) => {
                    const state = message.user_type === 'account_manager' ? 'info' : 'primary'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.user_type}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.user_type === 'account_manager' ? 'start' : 'end'
                      } mb-10`
                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', {
                          'd-none': message.template,
                        })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${message.user_type === 'account_manager' ? 'start' : 'end'
                            }`
                          )}
                          //@ts-ignore
                          ref={messagesEndRef}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.user_type === 'account_manager' ? (
                              <>
                                <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={message.account_manager_profile} />
                                </div>
                                <div className='ms-3'>
                                  {/* <a
                                                                        href='#'
                                                                        className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                                                    >
                                                                        {message.account_manger_name}
                                                                    </a> */}
                                  <span className='text-muted fs-7 mb-1'>
                                    <TimeAgo datetime={message.timestamp} locale='en_US' />
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>
                                    <TimeAgo datetime={message.timestamp} locale='en_US' />
                                  </span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >
                                    {message.influencer_name}
                                  </a>
                                </div>
                                <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={message.influencer_profile} />
                                </div>
                              </>
                            )}
                          </div>

                          <div
                            className={clsx(
                              'p-5 rounded',
                              `bg-light-${state}`,
                              'text-dark fw-bold mw-lg-400px',
                              `text-${message.user_type === 'account_manager' ? 'start' : 'end'}`
                            )}
                            data-kt-element='message-text'
                            dangerouslySetInnerHTML={{ __html: message.message }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </div>

            <div
              className='card-footer pt-4'
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
              style={{ height: '20vh', padding: '0px 0px' }}
            >
              <form className='p-6' onSubmit={SubmitHandler}>
                <textarea
                  className='form-control mb-3'
                  rows={1}
                  data-kt-element='input'
                  placeholder='Type a message'
                  name='message'
                  value={message}
                  onChange={updateMessage}
                  required
                ></textarea>

                <div className='d-flex flex-stack'>
                  {/* <div className='d-flex align-items-center me-2'>
                                    <button
                                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                        type='button'
                                        data-bs-toggle='tooltip'
                                        title='Coming soon'
                                    >
                                        <i className='bi bi-paperclip fs-3'></i>
                                    </button>
                                    <button
                                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                        type='button'
                                        data-bs-toggle='tooltip'
                                        title='Coming soon'
                                    >
                                        <i className='bi bi-upload fs-3'></i>
                                    </button>
                                </div> */}
                  <button className='btn btn-primary'>Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatBox
