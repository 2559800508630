import * as React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import {parseCookies} from 'nookies'
import {APIURL} from '../../../../config/config'
import {toast} from 'react-toastify'

interface InfluencerActionDialogProps {
  show: boolean
  onHide: () => void
  inFluencerInfo?: any
  blockLoading?: any
  setBlockLoading?: any
}

const InfluencerActionDialog: React.FC<InfluencerActionDialogProps> = ({
  inFluencerInfo,
  blockLoading,
  setBlockLoading,
  ...props
}) => {
  const [reason, setReason] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const cookies = parseCookies()
  const token = cookies?.token

  const handleBlockInfluencer = async () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    const body = JSON.stringify({
      influencer_id: inFluencerInfo?.influencer_id,
      reason: reason,
    })

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: body,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/block-influencer`, requestOptions)
    const result = await response.json()

    if (result?.success) {
      props.onHide()
      toast.success(result?.message)
    } else {
      setError(result?.message)
    }

    setBlockLoading(!blockLoading)
    setLoading(false)
  }

  return (
    <Modal {...props} size='sm' aria-labelledby='block-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='block-modal'>Suspend Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-5' controlId='block.description'>
            <Form.Label>Reason</Form.Label>
            <Form.Control
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              as='textarea'
              rows={3}
            />
          </Form.Group>
        </Form>

        <div className='d-flex justify-content-center'>
          <Button variant='info' size='sm' onClick={handleBlockInfluencer}>
            {loading ? <Spinner animation='border' size='sm' /> : 'Block'}
          </Button>
        </div>

        {error && (
          <Alert className='mt-4' variant='danger'>
            {error}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default InfluencerActionDialog
