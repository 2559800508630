/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
    className: string
    dashData: any
}

const CampaignChart: React.FC<Props> = ({ className, dashData }: Props) => {

    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        // console.log('dashData.yearly_booking', dashData.yearly_booking[0]);


        let Pending: any = [
            dashData.yearly_booking[0].p_1,
            dashData.yearly_booking[0].p_2,
            dashData.yearly_booking[0].p_3,
            dashData.yearly_booking[0].p_4,
            dashData.yearly_booking[0].p_5,
            dashData.yearly_booking[0].p_6,
            dashData.yearly_booking[0].p_7,
            dashData.yearly_booking[0].p_8,
            dashData.yearly_booking[0].p_9,
            dashData.yearly_booking[0].p_10,
            dashData.yearly_booking[0].p_11,
            dashData.yearly_booking[0].p_12,
        ];

        let Booked: any = [
            dashData.yearly_booking[0].a_1,
            dashData.yearly_booking[0].a_2,
            dashData.yearly_booking[0].a_3,
            dashData.yearly_booking[0].a_4,
            dashData.yearly_booking[0].a_5,
            dashData.yearly_booking[0].a_6,
            dashData.yearly_booking[0].a_7,
            dashData.yearly_booking[0].a_8,
            dashData.yearly_booking[0].a_9,
            dashData.yearly_booking[0].a_10,
            dashData.yearly_booking[0].a_11,
            dashData.yearly_booking[0].a_12,
        ];

        let Complete: any = [
            dashData.yearly_booking[0].c_1,
            dashData.yearly_booking[0].c_2,
            dashData.yearly_booking[0].c_3,
            dashData.yearly_booking[0].c_4,
            dashData.yearly_booking[0].c_5,
            dashData.yearly_booking[0].c_6,
            dashData.yearly_booking[0].c_7,
            dashData.yearly_booking[0].c_8,
            dashData.yearly_booking[0].c_9,
            dashData.yearly_booking[0].c_10,
            dashData.yearly_booking[0].c_11,
            dashData.yearly_booking[0].c_12,
        ];


        const chart = new ApexCharts(chartRef.current, getChartOptions(height, Pending, Booked, Complete))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode])

    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Recent Campaign</span>

                    <span className='text-muted fw-semibold fs-7'>More than {dashData.total_campaign} Campaign</span>
                </h3>

                {/* begin::Toolbar */}
                {/* <div className='card-toolbar' data-kt-buttons='true'>
                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
                        id='kt_charts_widget_2_year_btn'
                    >
                        Year
                    </a>

                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
                        id='kt_charts_widget_2_week_btn'
                    >
                        Week
                    </a>
                </div> */}
                {/* end::Toolbar */}
            </div>
            <div className='card-body'>
                <div ref={chartRef} id='kt_charts_widget_2_chart' style={{ height: '350px' }}></div>
            </div>
        </div>
    )
}

export { CampaignChart }

function getChartOptions(height: number, Pending: any, Booked: any, Complete: any): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-warning')
    const secondaryColor = getCSSVariableValue('--bs-info')
    const greenColor = getCSSVariableValue('--bs-success')

    return {
        series: [
            {
                name: 'Pending',
                data: Pending,
            },
            {
                name: 'Booked',
                data: Booked,
            },
            {
                name: 'Complete',
                data: Complete,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ''
                },
            },
        },
        colors: [baseColor, secondaryColor, greenColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
