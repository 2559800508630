/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'

import cookie from "js-cookie";
import { parseCookies } from "nookies";

import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'

const API_URL = process.env.REACT_APP_API_URL

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {


  const cookies = parseCookies();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      const login = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          user_type: 3,
        }),
      });

      const result = await login.json();

      if (login.status === 200) {
        if (result.success === true) {
          let userAuth = {
            api_token: result?.token,
            refreshToken: result?.token,
          }

          let userData: any = {
            first_name: result?.data?.user?.f_name,
            last_name: result?.data?.user?.l_name,
            fullname: `${result?.data?.user?.f_name} ${result?.data?.user?.l_name}`,
            email: result?.data?.user?.email,
            phone: result?.data?.user?.phone,
            user_type: result?.data?.user?.user_type,
            status: result?.data?.user?.status,
          }

          cookie.set("token", result?.token);
          cookie.set("user", JSON.stringify(userData));
          cookie.set("userAuth", JSON.stringify(userAuth));

          saveAuth(userAuth)
          setCurrentUser(userData)
        }
      } else if (login.status === 400) {
        setStatus(result?.message)
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      } else if (login.status === 401) {
        setStatus(result?.message)
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      } else if (login.status === 415) {
        if (result?.message?.email?.length > 0) {
          setStatus(result?.message?.email[0])
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        }
        if (result?.message?.password?.length > 0) {
          setStatus(result?.message?.password[0]);
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        }
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Login In to Your Account</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Welcome to Elite list</div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}


      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Don't have an account?{' '}
        <Link to='/registration' className='link-primary'>
          Register
        </Link>
      </div>
    </form>
  )
}
