import classes from './InfluencerSkeleton.module.css'

const InfluencerSkeleton = ({column = 6, height = 30}: {column?: number; height?: number}) => {
  return (
    <tr className={classes.skeleton}>
      <th className='text-center'></th>
      <td className='text-center'>
        <div
          style={{
            height: `${height}px`,
          }}
          className={classes.skeleton__avatar}
        />
      </td>
      {new Array(column).fill(0).map((_, index) => (
        <td key={index} className='text-center' />
      ))}
    </tr>
  )
}

export default InfluencerSkeleton
