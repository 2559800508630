/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { Link } from 'react-router-dom'

const MyProfile: FC = () => {
    const { currentUser, logout } = useAuth();
    return (
        <>
            <PageTitle>My Profile</PageTitle>
            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                        <div className="card-header cursor-pointer">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">Profile Details</h3>
                            </div>
                            <Link to={'/my-profile/update'}
                                className="btn btn-primary align-self-center"
                            >
                                Edit Profile
                            </Link>
                        </div>
                        <div className="card-body p-9">

                            <div className="row mb-6">
                                <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                                <div className="col-lg-8">
                                    <div
                                        className="image-input image-input-outline"
                                        data-kt-image-input="true"
                                        style={{
                                            backgroundImage: `url(${currentUser?.profile_photo})`
                                        }}
                                    >
                                        <div
                                            className="image-input-wrapper w-125px h-125px"
                                            style={{
                                                backgroundImage:
                                                    `url(${currentUser?.profile_photo})`
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-7">
                                <label className="col-lg-4 fw-bold fs-6">Full Name</label>
                                <div className="col-lg-8">
                                    <span className="fw-bolder fs-6 text-dark">{currentUser?.f_name} {currentUser?.l_name}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-bold fs-6">Email</label>
                                <div className="col-lg-8 fv-row">
                                    <span className="fw-bold fs-6">{currentUser?.email}</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-bold fs-6">
                                    Contact Phone
                                    <i
                                        className="fas fa-exclamation-circle ms-1 fs-7"
                                        data-bs-toggle="tooltip"
                                        title="Phone number must be active"
                                    />
                                </label>
                                <div className="col-lg-8 d-flex align-items-center">
                                    <span className="fw-bolder fs-6 me-2">{currentUser?.phone}</span>
                                    <span className="badge badge-success">Verified</span>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <label className="col-lg-4 fw-bold fs-6">Address</label>
                                <div className="col-lg-8">
                                    <span className="fw-bold fs-6">{currentUser?.address}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            {/* end::Row */}
        </>
    )
}


export default MyProfile;
