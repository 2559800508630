import Modal from 'react-bootstrap/Modal'
import ReviewTable from './ReviewTable'

interface ReviewDialogProps {
  show: boolean
  onHide: () => void
  influencer_id?: string
  campaign_id?: string
  bookingListInfo?: any
}

const ReviewDialog = (props: ReviewDialogProps) => {
  const {bookingListInfo} = props

  return (
    <Modal {...props} size='lg' aria-labelledby='rating-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='rating-modal'>Review List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReviewTable influencer_id={bookingListInfo?.influencer_id} />
      </Modal.Body>
    </Modal>
  )
}

export default ReviewDialog
