/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'

import cookie from "js-cookie";
import { parseCookies } from "nookies";

import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'

import { Registration } from '../../app/modules/auth/components/Registration'
import { ForgotPassword } from '../../app/modules/auth/components/ForgotPassword'
import { Login } from '../../app/modules/auth/components/Login'
import { AuthLayout } from '../../app/modules/auth/AuthLayout'

import { App } from '../App'
import { json } from 'node:stream/consumers';


const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const cookies = parseCookies();
  const { currentUser, saveAuth, setCurrentUser } = useAuth();
  const [token, setToken] = useState("");
  const [isToken, setISToken] = useState(false);

  useEffect(() => {
    if (!cookies?.userAuth) {
    } else {
      let AuthData = cookies?.userAuth
      let userAuth: any = JSON.parse(AuthData);
      saveAuth(userAuth)
    }

    if (!cookies?.user) {
    } else {
      let cuser: any = cookies?.user
      let user: any = JSON.parse(cuser);
      setCurrentUser(user)
    }

    if (!cookies?.token) {
      setToken("")
      setISToken(false)
    } else {
      console.log('cookies?.token', cookies?.token)
      setToken(cookies?.token)
      setISToken(true)
    }
  }, [cookies?.token, cookies?.user, cookies?.userAuth]);
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isToken ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              {/* <Route path='login/*' element={<AuthPage />} /> */}

              <Route path='*' element={<Navigate to='/login' />} />

              <Route element={<AuthLayout />}>
                <Route path='/login' element={<Login />} />
                <Route index element={<Login />} />
                <Route path='/registration' element={<Registration />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
