/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { PageTitle } from '../../_metronic/layout/core'
import { useNavigate } from 'react-router-dom'
import { parseCookies } from 'nookies'

import { baseUrl, APIURL } from '../../config/config'
import SubscriptionContent from '../components/SubscriptionContent'

const SubscriptionPackages: FC = () => {
  const navigate = useNavigate()
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [packages, setPackages] = useState([])
  const [purchaseLoading, setPurchaseLoading] = useState(false)
  const [purchaseList, setPurchaseList] = useState([])
  const [purchaseRequest, setPurchaseRequest] = useState<any>([])

  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState<number>()
  const [subId, setSubId] = useState<number>()

  const makePayment = async (id: number) => {
    setPurchaseLoading(true)

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${token}`)

    var raw = JSON.stringify({
      subscription_id: id,
      return_url: `${baseUrl}/subscription-packages`,
    })

    var requestOptions: any = {
      method: 'POST',
      body: raw,
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/choose-plan`, requestOptions)
    const result = await response.json()

    console.log('result', result)

    window.location.replace(`${result.data.url}?token=${result.data.token}`)

    // setPurchaseLoading(false);
  }

  const getPackages = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/subscriptions`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      setPackages(result?.data)
    }

    const purchase = await fetch(`${APIURL}/merchant/purchase-list`, requestOptions)

    const resultList = await purchase.json()
    if (resultList.success == true) {
      if (resultList?.data == '') {
        setPurchaseList([])
      } else {
        setPurchaseList(resultList?.data[0])
      }
    }

    var purchaseReqOptions: any = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }

    const purchaseReq = await fetch(`${APIURL}/merchant/purchase-request`, purchaseReqOptions)

    const resultReq = await purchaseReq.json()

    if (resultReq.success == true) {
      setPurchaseRequest(resultReq?.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <>
      {purchaseLoading ? (
        <>
          <div
            style={{ zIndex: '109', width: '100%', height: '100vh' }}
            className='drawer-overlay d-flex justify-content-center text-center align-items-center'
          >
            <div
              className='d-flex justify-content-center text-center align-items-center'
              style={{ width: '100%', height: 'auto', overflow: 'hidden' }}
            >
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <PageTitle>Subscription Packages</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='card' id='kt_pricing'>
          <div className='card-body p-lg-17'>
            <div className='d-flex flex-column'>
              <div className='mb-13 text-center'>
                <h3 className=' fw-bolder mb-5'>Subscription Packages List</h3>
              </div>
              <div className='row g-10'>
                {loading ? (
                  <div className='d-flex justify-content-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {packages.map((item: any, index: any) => (
                      <SubscriptionContent
                        item={item}
                        purchaseList={purchaseList}
                        makePayment={makePayment}
                        getPackages={getPackages}
                        key={`${item.id}.${index}`}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionPackages
