/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, lazy, FormEvent } from 'react'

import { useNavigate } from 'react-router-dom'
import { parseCookies } from 'nookies'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import moment from 'moment'

import { PageTitle } from '../../../_metronic/layout/core'
import { baseUrl, APIURL } from '../../../config/config'

import { FaFacebook, FaInstagram, FaTiktok, FaTrash } from 'react-icons/fa'

const TimeSlot = lazy(() => import('./AddCampaign/TimeSlot'))
const GMapPicker = lazy(() => import('../../../app/components/GMapPicker'))

type Campaign = {
  category_id: string
  brand_id: string
  name: string
  about: string
  start_date: string
  end_date: string
  location: string
  latitude: string
  longitude: string
  thumb_image: string
  do_and_donot: string
  voucher_id: string
  voucher_applicable: string
  time_slot?: any | any[]
  requirements: string
  deliverables: string
  socials: any
  images: any
}

const CreateNewCampaign: FC = () => {
  const navigate = useNavigate()
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(false)
  const [mapLoading, setMapLoading] = useState(true)
  const [imgLoading, setImgLoading] = useState(false)
  const [imgsLoading, setImgsLoading] = useState(false)

  const [fetchLoading, setFetchLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [cats, setCats] = useState([])
  const [socialList, setSocialList] = useState([])
  const [brands, setBrands] = useState([])

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const [vouchers, setVouchers] = useState<any>([])
  const [latlng, setLatlng] = useState({
    lat: '',
    lng: '',
  })
  const [campaign, setCampaign] = useState<Campaign>({
    category_id: '',
    brand_id: '',
    name: '',
    about: '',
    start_date: '',
    end_date: '',
    location: '',
    latitude: '',
    longitude: '',
    thumb_image: '',
    do_and_donot: '',
    voucher_id: '',
    voucher_applicable: '',
    time_slot: [],
    requirements: '',
    deliverables: '',
    socials: [
      {
        social_id: 1,
        tags: [],
      },
    ],
    images: [
      {
        link: '',
        type: '',
        screenshot: ''
      },
    ],
  })

  const [jsonData, setJsonData] = useState([])
  const [socials, setSocials] = useState<any>({
    id: '',
    social_id: '',
    tags: '',
  })
  const [socialsErr, setSocialsErr] = useState(false)

  // {
  //     id: "",
  //     social_id: "",
  //     tags: ""
  // }

  const [images, setImages] = useState<any>([]);

  const fetchCategoryBrands = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const categories = await fetch(`${APIURL}/admin/categories`, requestOptions)
    const catresult = await categories.json()
    if (catresult.success == true) {
      setCats(catresult?.data)
    }

    // const vouchers = await fetch(`${APIURL}/merchant/vouchers`, requestOptions)
    // const vresult = await vouchers.json()
    // if (vresult.success == true) {
    //   setVouchers(vresult?.data)
    // }

    const socialList = await fetch(`${APIURL}/merchant/social-list`, requestOptions)
    const scList = await socialList.json()
    if (scList.success == true) {
      setSocialList(scList?.data)
    }

    // const ifconfigRes = await fetch(`https://ifconfig.co/json?ip=`);
    // const ifconfig = await ifconfigRes.json();
    // console.log('ifconfig', ifconfig
    setFetchLoading(false)

    setTimeout(() => {
      setMapLoading(false)
    }, 3500);
  }

  useEffect(() => {
    setImgsLoading(false)
    setFetchLoading(true)
    fetchCategoryBrands()
  }, [])

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)
      myHeaders.append('Content-Type', 'application/json')

      // let search = reader.result.search("data:image/jpeg;base64,", "")
      let imageFile

      //@ts-ignore
      if (reader.result.search('data:image/jpeg;base64,') == 0) {
        //@ts-ignore
        imageFile = reader.result.replace('data:image/jpeg;base64,', '')
        //@ts-ignore
      } else if (reader.result.search('data:image/png;base64,') == 0) {
        //@ts-ignore
        imageFile = reader.result.replace('data:image/png;base64,', '')
      }

      var raw = JSON.stringify({
        directory_name: 'campaign',
        type: 'image',
        //@ts-ignore
        file: imageFile,
      })

      var requestOptions: any = {
        method: 'POST',
        body: raw,
        headers: myHeaders,
        redirect: 'follow',
      }

      const upload = await fetch(`${APIURL}/admin/upload-file`, requestOptions)
      const result = await upload.json()

      setCampaign({
        ...campaign,
        [name]: result.data.file_url,
      })

      setImgLoading(false)
    }

    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  function ImagesUploading(id: number, type: string, file: any, file_name: string) {
    setImgsLoading(true);
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)
      var formdata = new FormData();
      let imageFile;
      let requestOptions: any;

      if (type == 'image') {

        myHeaders.append('Content-Type', 'application/json')

        //@ts-ignore
        if (reader.result.search('data:image/jpeg;base64,') == 0) {
          //@ts-ignore
          imageFile = reader.result.replace('data:image/jpeg;base64,', '')
          //@ts-ignore
        } else if (reader.result.search('data:image/png;base64,') == 0) {
          //@ts-ignore
          imageFile = reader.result.replace('data:image/png;base64,', '')
        }

        var raw = JSON.stringify({
          //@ts-ignore image/png;base64
          "file": imageFile,
          "type": type,
          "directory_name": "campaign",
        });

        requestOptions = {
          method: "POST",
          body: raw,
          headers: myHeaders,
          redirect: "follow",
        };

      } else if (type == 'video') {
        formdata.append("file", file, file_name);
        formdata.append("type", type);
        formdata.append("directory_name", "campaign");

        requestOptions = {
          method: 'POST',
          body: formdata,
          headers: myHeaders,
          redirect: 'follow',
        }
      }

      const upload = await fetch(`${APIURL}/admin/upload-file`, requestOptions);
      const result = await upload.json();

      let image = {
        id: id + 1,
        link: result.data.file_url,
        type: type,
        screenshot: result.data?.screenshot,
      }

      setImages((olddata: any) => [...olddata, image])

      setImgsLoading(false)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadImages = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    for (let i = 0; i < e.target.files.length; i++) {

      let fileType = e.target.files[i].type;

      if (fileType.match(/video/g) == "video") {
        let getType = fileType.match(/video/g)
        // console.log('e.target.files', e.target.files[0].type, getType[0]);
        ImagesUploading(i, getType[0], e.target.files[i], e.target.files[i].name)
      } else if (fileType.match(/image/g) == "image") {
        let getType = fileType.match(/image/g)
        // console.log('e.target.files', e.target.files[0].type, getType[0]);
        ImagesUploading(i, getType[0], e.target.files[i], e.target.files[i].name)
      }

    }
  }

  const ImageHandel = (e: any) => {
    e.preventDefault()
    // setProduct({ ...product, images: images });
  }

  const Icon = (social_id: any) => {
    // Facebook == 1
    // Tiktok == 2
    // Instagram == 3
    return (
      <>
        {
          social_id == 1 ? <><FaFacebook className='flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500' /></> : <></>
        }

        {
          social_id == 2 ? <><FaTiktok className='flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500' /></> : <></>
        }

        {
          social_id == 3 ? <><FaInstagram className='flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500' /></> : <></>
        }
      </>
    )
  }

  const updateCampaign = (e: { target: { name: any; value: any } }) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.value })
  }

  const getBrand = async (catId: number) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const brands = await fetch(`${APIURL}/merchant/brands-by-category?category_id=${catId}`, requestOptions)
    const branresult = await brands.json()
    const branData = branresult?.data
    // console.log('branresult', branData)
    setBrands(branData)
  }

  const UpdateCat = (e: { target: { name: any; value: any } }) => {
    setCampaign({ ...campaign, [e.target.name]: e.target.value })
    getBrand(e.target.value)
  }

  const updateData = (e: any) => {
    setSocials({
      ...socials,
      [e.target.name]: e.target.value,
    })
  }

  const handleStartDate = (dob: any) => {
    let NewDate = moment(dob).format('yyyy-MM-D')
    setStartDate(dob)
    setCampaign({ ...campaign, start_date: NewDate })
  }

  const handleEndDate = (dob: any) => {
    let NewDate = moment(dob).format('yyyy-MM-D')
    setEndDate(dob)
    setCampaign({ ...campaign, end_date: NewDate })
  }

  const SocialsHandel = (e: any) => {
    e.preventDefault()

    // console.log('socials 111', socials);
    // console.log('socials.social_id', socials.social_id);
    // console.log('socials.tags', socials.tags);

    if (socials.length == 0) {
      setSocialsErr(true)
    } else {
      if (socials.social_id == '' || socials.tags == '') {
        setSocialsErr(true)
      } else if (socials.social_id == undefined || socials.tags == undefined) {
        setSocialsErr(true)
      } else {
        setSocialsErr(false)
        let social = {
          id: jsonData.length + 1,
          social_id: socials.social_id,
          tags: socials.tags.split(','),
        }
        setJsonData((olddata): any => [...olddata, social])
        setSocials({
          ...socials,
          id: '',
          social_id: '',
          tags: '',
        })
        // setSocials([]);
      }
    }
  }

  // setSocials({
  //     ...socials,
  //     id: "",
  //     social_id: "",
  //     tags: "",
  // });

  const handleRemoveItem = (id: number) => {
    //@ts-ignore
    setJsonData(jsonData.filter((item) => item.id !== id))
  }

  const handleRemoveImages = (id: number) => {
    //@ts-ignore
    setImages(images.filter((item) => item.id !== id))
  }

  // CampaignSubmitHandler
  const CampaignSubmitHandler = async (e: FormEvent) => {
    e.preventDefault()
    if (campaign?.time_slot.length === 0) {
      toast.error('Please select time slot')
      return
    }

    setLoading(true)

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'POST',
      body: JSON.stringify({
        ...campaign,
        latitude: latlng.lat,
        longitude: latlng.lng,
        socials: jsonData,
        images: images,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/campaign`, requestOptions)
    const result = await response.json()
    // console.log('result :-', result);

    if (result.success === true) {
      toast.success(result?.message)
      navigate('/campaign')
    } else if (result.success === false) {
      toast.error(`All field is required`)
    }
    setLoading(false)
  }

  const handleClass = () => {
    const datepicker = document.querySelector('.react-datepicker-popper');
    //@ts-ignore
    const getAttribute = datepicker.getAttribute('style');
    //@ts-ignore
    datepicker.setAttribute("style", `${getAttribute} z-index: 100;`);
  };

  return (
    <>
      <PageTitle>Add Campaign</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='card'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder text-dark'>Add Campaign</span>
              {/* <span className="text-muted mt-1 fw-bold fs-7">
                                        Preview monthly events
                                    </span> */}
            </h3>
          </div>
          <div className='card-body'>
            {fetchLoading ? (
              <div className='d-flex justify-content-center'>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <form
                  className='form row g-3 fv-plugins-bootstrap5 fv-plugins-framework mt-4'
                  onSubmit={CampaignSubmitHandler}
                >
                  <div className='col-sm-12 col-md-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Category</span>
                    </label>
                    <select name='category_id' onChange={UpdateCat} className='form-select'>
                      <option value='' selected>
                        Choose Category...
                      </option>
                      {cats.map((item: any, index: any) => (
                        <option key={`${item}.${index}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-sm-12 col-md-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Brand</span>
                    </label>
                    <select name='brand_id' onChange={updateCampaign} className='form-select'>
                      <option value='' selected>
                        Choose Brand...
                      </option>
                      {brands.map((item: any, index: any) => (
                        <option key={`${item.id}.${index}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className='col-sm-12 col-md-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Voucher</span>
                    </label>
                    <select name='voucher_id' onChange={updateCampaign} className='form-select'>
                      <option value='' selected>
                        Choose voucher
                      </option>
                      {vouchers.map((item: any, index: any) => (
                        <option key={`${item.id}.${index}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  {/* 1 = delivery, 2 = walk in  */}
                  {/* <div className='col-sm-12 col-md-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Voucher Applicable</span>
                    </label>
                    <select
                      name='voucher_applicable'
                      onChange={updateCampaign}
                      className='form-select'
                    >
                      <option value='' selected>
                        Choose Applicable
                      </option>
                      <option value='1'>Delivery</option>
                      <option value='2'>Walk In</option>
                    </select>
                  </div> */}

                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Name</span>
                    </label>
                    <input
                      type='text'
                      name='name'
                      value={campaign.name}
                      onChange={updateCampaign}
                      className='form-control'
                      required
                    />
                  </div>
                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>About</span>
                    </label>
                    <textarea
                      defaultValue=''
                      name='about'
                      value={campaign.about}
                      onChange={updateCampaign}
                      className='form-control'
                      rows={4}
                      cols={40}
                    />
                  </div>

                  <div className='col-sm-12 col-md-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Start Date</span>
                    </label>
                    <DatePicker
                      className='form-control'
                      placeholderText='Select start date'
                      timeInputLabel='Time:'
                      dateFormat='yyyy-MM-dd'
                      // showTimeInput
                      selected={startDate}
                      //@ts-ignore
                      onSelect={setStartDate}
                      onChange={(startDate) => handleStartDate(startDate)}
                      startDate={startDate}
                      endDate={endDate}
                      required
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      // minDate={new Date()}
                      onInputClick={handleClass}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>End Date</span>
                    </label>
                    <DatePicker
                      className='form-control'
                      placeholderText='Select end date'
                      timeInputLabel='Time:'
                      dateFormat='yyyy-MM-dd'
                      // showTimeInput
                      //@ts-ignore
                      selected={endDate}
                      onChange={(endDate) => handleEndDate(endDate)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      required
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onInputClick={handleClass}
                    />
                  </div>

                  <div className='col-sm-12 col-md-12 d-flex justify-content-center'>
                    <GMapPicker latlng={latlng} setLatlng={setLatlng} mapLoading={mapLoading} campaign={campaign} setCampaign={setCampaign} />
                  </div>

                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Location</span>
                    </label>
                    <input
                      type='text'
                      name='location'
                      value={campaign.location}
                      onChange={updateCampaign}
                      className='form-control'
                      required
                    />
                  </div>

                  <div className='col-sm-6 col-md-6 d-none'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Latitude</span>
                    </label>
                    <input
                      type='text'
                      name='latitude'
                      value={latlng.lat}
                      onChange={updateCampaign}
                      className='form-control'
                      disabled
                    />
                  </div>

                  <div className='col-sm-6 col-md-6 d-none'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Longitude</span>
                    </label>
                    <input
                      type='text'
                      name='longitude'
                      value={latlng.lng}
                      onChange={updateCampaign}
                      className='form-control'
                      disabled
                    />
                  </div>

                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Thumb Image</span>
                    </label>

                    <input
                      type='file'
                      className='form-control mb-2'
                      name='thumb_image'
                      onChange={(e) => uploadFile(e)}
                    />
                    <picture className='mt-2 mb-2'>
                      {imgLoading ? (
                        <>
                          <div
                            className='d-flex justify-content-center img-fluid form-control'
                            style={{ width: '150px', height: '100px' }}
                          >
                            <div className='spinner-border' role='status'>
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {campaign.thumb_image == '' ? (
                            <>
                              <img
                                src={`${baseUrl}/assets/img/thum.jpg`}
                                className='img-fluid form-control'
                                style={{ width: '150px', height: '100px' }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={`${campaign.thumb_image}`}
                                className='img-fluid form-control'
                                style={{ width: '150px', height: '100px' }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </picture>
                  </div>

                  <TimeSlot campaign={campaign} setCampaign={setCampaign} />

                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Requirements</span>
                    </label>
                    <textarea
                      defaultValue=''
                      name='requirements'
                      value={campaign.requirements}
                      onChange={updateCampaign}
                      className='form-control'
                      rows={4}
                      cols={40}
                    />
                  </div>

                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Do And Do Not</span>
                    </label>
                    <textarea
                      defaultValue=''
                      name='do_and_donot'
                      value={campaign.do_and_donot}
                      onChange={updateCampaign}
                      className='form-control'
                      rows={4}
                      cols={40}
                    />
                  </div>

                  <div className='col-sm-12 col-md-12 row mt-4'>

                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Social</span>

                      <span className=''>{`(Example : Food,Fashion,Travel)`}</span>
                    </label>

                    <div className={`input-group ${socialsErr ? `has-validation` : ``}`}>
                      {socials.social_id == '' ? (
                        <>
                          <select
                            name='social_id'
                            onChange={updateData}
                            className={`btn btn-light text-start ${socialsErr ? `is-invalid` : ``}`}
                            style={{ width: '155px' }}
                          >
                            <option value='' selected>
                              Select social
                            </option>
                            {socialList.map((item: any, index: any) => (
                              <option key={`${item}.${index}`} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </>
                      ) : (
                        <>
                          <select
                            name='social_id'
                            onChange={updateData}
                            className={`btn btn-light text-start ${socialsErr ? `is-invalid` : ``}`}
                            style={{ width: '155px' }}
                          >
                            <option value=''>Select social</option>
                            {socialList.map((item: any, index: any) => (
                              <option key={`${item}.${index}`} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </>
                      )}

                      <input
                        type='text'
                        name='tags'
                        value={socials.tags}
                        onChange={updateData}
                        className={`form-control ${socialsErr ? `is-invalid` : ``}`}
                        placeholder='Hash Tag (Example : Food,Fashion,Travel)'
                      />
                      <button className='btn btn-primary btn-sm mr-4' onClick={SocialsHandel}>
                        Add Tag
                      </button>
                      {socialsErr ? (
                        <div className='invalid-feedback'>Please Add socials tags.</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className='col-sm-12 col-md-12'>
                      <div className='w-100 mb-10'>
                        {jsonData?.length > 0 ? (
                          <>
                            {jsonData.map((socials: any, index: any) => (
                              <div
                                key={`${socials.social_id}.${index}`}
                                className='d-flex align-items-center mb-5'
                              >
                                <span className='svg-icon svg-icon-1 svg-icon-success pe-2'>
                                  {Icon(socials.social_id)}
                                </span>
                                <span className='fw-bold fs-6 text-gray-800 flex-grow-1 pe-2'>
                                  {`[${socials.tags}]`}
                                </span>
                                <span className='svg-icon svg-icon-1 svg-icon-danger pe-auto'>
                                  <FaTrash
                                    className='mt-1'
                                    onClick={() => handleRemoveItem(socials?.id)}
                                  />
                                </span>
                              </div>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-12 col-md-12'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Deliverables</span>
                    </label>
                    <textarea
                      defaultValue=''
                      name='deliverables'
                      value={campaign.deliverables}
                      onChange={updateCampaign}
                      className='form-control'
                      rows={4}
                      cols={40}
                    />
                  </div>

                  <div className='row mb-2'>
                    <label className='col-md-12 col-form-label'>Gallery</label>
                    <div className='col-md-12'>
                      <input
                        className='form-control'
                        type='file'
                        id='formFile'
                        name='image'
                        multiple
                        onChange={(e) => uploadImages(e)}
                      />
                      <div className='file-preview box sm mt-4'>
                        <div className='col-sm-12 col-md-12 mb-4'>
                          <div className='row mb-10'>

                            {
                              imgsLoading ? <>
                                <div className='col-md-12'>
                                  <div className='d-flex justify-content-center'>
                                    <div className='spinner-border' role='status'>
                                      <span className='visually-hidden'>Loading...</span>
                                    </div>
                                  </div>
                                </div>
                              </> : <></>
                            }

                            {images?.length > 0 ? (
                              <>
                                {' '}
                                {images.map((item: any, index: any) => (
                                  <div
                                    key={`${item.id}.${index}`}
                                    className='col-sm-3  d-flex align-items-center mb-5'
                                  >
                                    <picture
                                      className='img-thumbnail position-relative'
                                      style={{ width: '250px' }}
                                    >
                                      <span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2'>
                                        <FaTrash onClick={() => handleRemoveImages(item?.id)} />
                                      </span>
                                      {
                                        item.type == "video" ?
                                          <>

                                            <img className='img-thumbnail' src={item?.screenshot} alt='' />
                                          </> : <>

                                            <img className='img-thumbnail' src={item?.link} alt='' />
                                          </>
                                      }
                                    </picture>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <small className="text-muted">
                                                    These images are visible in product details page
                                                    gallery. Use 600x600 sizes images.
                                                </small> */}
                    </div>
                  </div>

                  {/* <div className="col-sm-12 col-md-12">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    name="content"
                                                    value={socials.content}
                                                    onChange={updateData}
                                                    className="form-control"
                                                    placeholder="Features"
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm mr-4"
                                                // onClick={SocialsHandel}
                                                >
                                                    Add Package Features
                                                </button>
                                            </div>
                                        </div> */}
                  <div>
                    {loading ? (
                      <button className='btn btn-lg btn-primary mb-5' disabled>
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <>
                        <button className='btn btn-lg btn-primary mb-5'>Submit</button>
                      </>
                    )}
                  </div>
                  <div className='col-sm-12 col-md-12'></div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default CreateNewCampaign
