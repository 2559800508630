import * as React from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {APIURL} from '../../../../config/config'
import {toast} from 'react-toastify'
import {parseCookies} from 'nookies'

interface UnBlockBtnProps {
  fetchInfluencer: () => void
  item?: any
}

const UnBlockBtn = ({fetchInfluencer, item}: UnBlockBtnProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)

  const cookies = parseCookies()
  const token = cookies?.token

  const handleUnblockInfluencer = async (influencer: any) => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    const body = JSON.stringify({
      influencer_id: influencer?.influencer_id,
    })

    const requestOptions: any = {
      method: 'POST',
      body,
      headers: myHeaders,
    }

    const response = await fetch(`${APIURL}/merchant/unblock-influencer`, requestOptions)
    const result = await response.json()

    if (result.success === true) {
      toast.success(result?.message)
      fetchInfluencer()
    }

    setLoading(false)
  }

  return (
    <Button
      className='btn btn-light  btn-sm'
      size='sm'
      onClick={() => handleUnblockInfluencer(item)}
      disabled={loading}
    >
      {loading ? <Spinner animation='border' size='sm' /> : 'Unblock'}
    </Button>
  )
}

export default UnBlockBtn
