import {Modal} from 'react-bootstrap'

interface ViewStatsProps {
  show: boolean
  onHide: () => void
  bookingListInfo?: any
}

const ViewStats = (props: ViewStatsProps) => {
  const {bookingListInfo} = props

  const stats = bookingListInfo?.influencer?.social_stats

  return (
    <Modal {...props} size='lg' aria-labelledby='preview-tutorial-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='preview-tutorial-modal'>
          {bookingListInfo?.influencer?.full_name}'s View Stats
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          id='kt_view_stats_table'
        >
          <thead>
            <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
              <th className='min-w-200px sorting'>Social SS</th>
              <th className='min-w-125px sorting'>Media</th>
              <th className='min-w-200px sorting'>Followers</th>
              <th className='min-w-300px sorting'>Link</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {
              stats?.length === 0 && (
                <tr>
                  <td colSpan={4} className='text-center'>
                    No Data Found
                  </td>
                </tr>
              )
            }

            {stats?.map(
              ({id, followers_screenshot, profile_url, followers, social}: any, i: number) => (
                <tr className='odd' key={`${i}.${id}`}>
                  <td>
                    <img src={followers_screenshot} alt='s_ss' className='w-50px h-50px rounded' />
                  </td>
                  <td>{social?.name}</td>
                  <td>{followers}</td>
                  <td>
                    <a href={profile_url} target='_blank' rel='noopener noreferrer'>
                      {profile_url}
                    </a>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
}

export default ViewStats
