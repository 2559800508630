/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'

import {baseUrl, APIURL} from '../../config/config'

import {parseCookies} from 'nookies'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {Switch} from '@headlessui/react'

import {BsFillSunFill, BsMoonStarsFill} from 'react-icons/bs'
import Table from 'react-bootstrap/Table'

import CatStatus from '../../app/components/CatStatus'
import InfluencerSkeleton from './campaign/Influencer/InfluencerSkeleton'

const Category: FC = () => {
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [conLoading, setConLoading] = useState(false)

  const [imgLoading, setImgLoading] = useState(false)
  const [iconImg, setIconImg] = useState('')
  const [edit, setEdite] = useState(false)
  const [mainCats, setMainCats] = useState<any>([])

  const fetchCategory = async () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/categories`, requestOptions)
    const result = await response.json()

    console.log('result', result)
    if (result.success == true) {
      setMainCats(result?.data)
      setLoading(false)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchCategory()
  }, [])

  const [category, setCategory] = useState({
    name: '',
    points: '',
    icon: '',
  })

  const [catEdit, setCatEdit] = useState({
    id: '',
    name: '',
    points: '',
    icon: '',
  })

  const updateCategory = (e: {target: {name: any; value: any}}) => {
    setCategory({...category, [e.target.name]: e.target.value})
  }
  const editCategory = (e: {target: {name: any; value: any}}) => {
    setCatEdit({...catEdit, [e.target.name]: e.target.value})
  }

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      // let search = reader.result.search("data:image/jpeg;base64,", "")
      let imageFile
      //@ts-ignore
      imageFile = reader.result.replace('data:image/svg+xml;base64,', '')

      console.log('reader.result', reader.result)

      //@ts-ignore
      setIconImg(reader.result)

      setCategory({
        ...category,
        icon: imageFile,
      })

      setCatEdit({
        ...catEdit,
        icon: imageFile,
      })

      setImgLoading(false)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    if (e.target.files[0].type !== 'image/svg+xml') {
      toast.error('Invalid file format! Please add a SVG Image')
      return
    } else {
      setImgLoading(true)
      getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
    }
  }

  console.log('category', category)

  async function SubmitHandler(e: any) {
    e.preventDefault()
    setConLoading(true)

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'POST',
      body: JSON.stringify({
        ...category,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/category`, requestOptions)
    const result = await response.json()

    // console.log("response.status: ", response);
    if (response.status == 200) {
      if (result.success == true) {
        toast.success(result.message)
        fetchCategory()
        setCategory({
          ...category,
          name: '',
          points: '',
          icon: '',
        })
        setIconImg('')
      }
    } else if (response.status == 415) {
      // console.log("message", result);
      if (result?.message?.name?.length > 0) {
        toast.error(result?.message?.name[0])
      }

      if (result?.message?.points?.length > 0) {
        toast.error(result?.message?.points[0])
      }

      if (result?.message?.icon?.length > 0) {
        toast.error(result?.message?.icon[0])
      }
    }

    setConLoading(false)
  }

  async function editData(id: any) {
    var myHeaders = new Headers()

    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/category?id=${id}`, requestOptions)
    // console.log("response.status: ", response);
    const result = await response.json()
    if (response.status == 200) {
      if (result.success == true) {
        let CatData = {
          id: result.data.id,
          name: result.data.name,
          points: result.data.points,
          icon: '',
        }
        setIconImg(result.data.icon_path)
        setCatEdit(CatData)
        setEdite(true)
      }
    }
  }

  async function EditHandler(e: any) {
    e.preventDefault()
    setConLoading(true)
    var myHeaders = new Headers()

    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'PUT',
      body: JSON.stringify({
        ...catEdit,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/category`, requestOptions)
    // console.log("response.status: ", response);
    const result = await response.json()
    if (response.status == 200) {
      if (result.success == true) {
        toast.success(result.message)
        fetchCategory()
        setCatEdit({
          ...catEdit,
          id: '',
          name: '',
          points: '',
          icon: '',
        })
        setEdite(false)
        setIconImg('')
      }
    } else if (response.status == 415) {
      // console.log("message", result);
      if (result?.message?.name?.length > 0) {
        toast.error(result.message?.name[0])
      }

      if (result?.message?.points?.length > 0) {
        toast.error(result?.message?.points[0])
      }
    }
    setConLoading(false)
  }

  return (
    <>
      <PageTitle>Category</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* <div className='col-xl-4'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-info py-5'>
              <h3 className='card-title fw-bolder text-white'>
                {edit ? 'Update Category' : 'New Category'}
              </h3>
            </div>
            <div className='card-body p-2'>
              {edit ? (
                <form className='p-4 z-40' onSubmit={EditHandler}>
                  <input type='hidden' name='id' value={catEdit.id} />

                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={catEdit.name}
                      name='name'
                      onChange={editCategory}
                      placeholder='Name'
                      autoComplete='off'
                    />
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Points</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={catEdit.points}
                      name='points'
                      onChange={editCategory}
                      placeholder='Points'
                      autoComplete='off'
                    />
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Logo</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='file'
                      name='icon'
                      onChange={(e) => uploadFile(e)}
                    />
                    <picture className='mt-2 mb-2'>
                      {imgLoading ? (
                        <>
                          <div
                            className='d-flex justify-content-center img-fluid form-control mt-2 mb-2'
                            style={{ width: '150px', height: '100px' }}
                          >
                            <div className='spinner-border' role='status'>
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {iconImg == '' ? (
                            <>
                              <img
                                src={`${baseUrl}/assets/img/thum.jpg`}
                                className='img-fluid form-control mt-2 mb-2'
                                style={{ width: '150px', height: '100px' }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={`${iconImg}`}
                                className='img-fluid form-control mt-2 mb-2'
                                style={{ width: '150px', height: '100px' }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </picture>
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  <div>
                    {conLoading ? (
                      <>
                        <button className='btn btn-lg btn-primary w-100 mb-5' disabled>
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </button>
                      </>
                    ) : (
                      <>
                        <button className='btn btn-lg btn-primary w-100 mb-5'>Update</button>
                      </>
                    )}
                  </div>
                </form>
              ) : (
                <form className='p-4 z-40' onSubmit={SubmitHandler}>
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={category.name}
                      name='name'
                      onChange={updateCategory}
                      placeholder='Name'
                      autoComplete='off'
                    />
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Points</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='text'
                      value={category.points}
                      name='points'
                      onChange={updateCategory}
                      placeholder='Points'
                      autoComplete='off'
                    />
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>

                  <div className='fv-row mb-10 fv-plugins-icon-container'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Logo</label>
                    <input
                      className='form-control form-control-lg form-control-solid'
                      type='file'
                      name='icon'
                      onChange={(e) => uploadFile(e)}
                    />
                    <picture className='mt-2 mb-2'>
                      {imgLoading ? (
                        <>
                          <div
                            className='d-flex justify-content-center img-fluid form-control mt-2 mb-2'
                            style={{ width: '150px', height: '100px' }}
                          >
                            <div className='spinner-border' role='status'>
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {iconImg == '' ? (
                            <>
                              <img
                                src={`${baseUrl}/assets/img/thum.jpg`}
                                className='img-fluid form-control mt-2 mb-2'
                                style={{ width: '150px', height: '100px' }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={`${iconImg}`}
                                className='img-fluid form-control mt-2 mb-2'
                                style={{ width: '150px', height: '100px' }}
                              />
                            </>
                          )}
                        </>
                      )}
                    </picture>
                    <div className='fv-plugins-message-container invalid-feedback'></div>
                  </div>
                  <div>
                    {conLoading ? (
                      <>
                        <button className='btn btn-lg btn-primary w-100 mb-5' disabled>
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </button>
                      </>
                    ) : (
                      <>
                        <button className='btn btn-lg btn-primary w-100 mb-5'>Submit</button>
                      </>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div> */}

        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-success py-5'>
              <h3 className='card-title fw-bolder text-white'>Categories List</h3>
            </div>
            <div className='card-body p-2'>
              <Table striped bordered hover responsive='sm'>
                <thead>
                  <tr>
                    <th className='fs-4'>Icon</th>
                    <th className='fs-4'>Name</th>
                    <th className='fs-4'>Points</th>
                    {/* <th className='fs-4 text-center'>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <>
                      {new Array(10).fill(0).map((_, index) => (
                        <InfluencerSkeleton column={2} height={40} key={index} />
                      ))}
                    </>
                  )}

                  {mainCats.map(
                    (
                      cat: {
                        id: number
                        name: string
                        points: string
                        icon_path: string
                        status: number
                      },
                      index: number
                    ) => (
                      <tr key={`${cat.id}.${index}`} className='align-middle'>
                        <td>
                          <picture className='symbol symbol-45px me-5'>
                            <img src={cat.icon_path} alt='' />
                          </picture>
                        </td>
                        <td>{cat.name}</td>
                        <td>{cat.points}</td>
                        {/* <td className="d-flex justify-content-center">
                          <span className='mr-2 h-8 flex items-center'>
                            <CatStatus id={cat.id} status={cat.status} />
                          </span>
                          <button className='btn btn-success' onClick={() => editData(cat.id)}>
                            Edit
                          </button>
                        </td> */}
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
            <div className='col-sm-12 my-4'></div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default Category
