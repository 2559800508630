/* eslint-disable eqeqeq */
import React from 'react'
import useSWR from 'swr'
import { APIURL } from '../../config/config'
import { parseCookies } from 'nookies'
import { PageTitle } from '../../_metronic/layout/core'
import { Button } from 'react-bootstrap'

const Faqs = () => {
  const [page, setPage] = React.useState(1)
  const [pagination, setPagination] = React.useState({} as any)

  const cookies = parseCookies()
  const token = cookies?.token

  const fetcher = async (url: string) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    const result = await response.json()

    setPage(result?.paginate?.current_page)
    setPagination(result?.paginate)

    console.log('result?.data', result?.data);
    return result?.data
  }

  const { data, error, isLoading } = useSWR(`${APIURL}/merchant/faqs?page=${page}`, fetcher)

  if (error) return <div>failed to load</div>

  return (
    <>
      <PageTitle>FAQs</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-12'>
          {isLoading && (
            <div className='card mb-2'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Loading...</span>
                </h3>
              </div>
            </div>
          )}

          {!isLoading && !data?.length && (
            <div className='card mb-2'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>No data found</span>
                </h3>
              </div>
            </div>
          )}

          {data?.map(({ title, content, id, status }: any) => (
            <>
              {
                status == '1' ? <>
                  <div className='card mb-2' key={id}>
                    <div className='card-header border-0 pt-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
                        <span className='text-muted mt-2 mb-1 fw-bold fs-5'>{content}</span>
                      </h3>
                    </div>
                  </div>
                </> : <></>
              }
            </>
          ))}
        </div>
      </div>

      {pagination?.next_url && (
        <div className='d-flex align-items-center'>
          <ul className='pagination'>
            <li className='page-item'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => setPage(page - 1)}
                disabled={pagination?.prev_url ? false : true}
              >
                Previous
              </Button>
            </li>

            <li className='page-item'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => setPage(page + 1)}
                disabled={pagination?.next_url ? false : true}
              >
                Next
              </Button>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default Faqs
