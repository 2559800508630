/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
//   CardsWidget7,
//   CardsWidget17,
//   CardsWidget20,
//   ListsWidget26,
//   EngageWidget10,
//   ChartsWidget2,
//   StatisticsWidget3,
// } from '../../../_metronic/partials/widgets'

import { ActiveCampaign } from '../../components/dashboard/ActiveCampaign';
import { TotalBooking } from '../../components/dashboard/TotalBooking';
import { ExternalLinks } from '../../components/dashboard/ExternalLinks';
import { InfluencerJoin } from '../../components/dashboard/InfluencerJoin';
import { CampaignChart } from '../../components/dashboard/CampaignChart';

import { baseUrl, APIURL } from "../../../config/config";
import { parseCookies } from 'nookies';

const DashboardWrapper: FC = () => {
  const intl = useIntl();

  const cookies = parseCookies();
  const token = cookies?.token;

  const [loading, setLoading] = useState(true);
  const [dashData, setDashData] = useState([]);



  const fetchDash = async (page: number) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(`${APIURL}/merchant/dashboard`, requestOptions);
    const result = await response.json();

    if (result.success == true) {
      setDashData(result?.data);
    }

    setLoading(false);
    // console.log('result', result);
  };

  useEffect(() => {
    fetchDash(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-6'>
          <div className='row mb-5'>
            <div className='col-md-6'
              style={{ height: '185px' }}
            >
              {
                loading ? <></> :
                  <>
                    <ActiveCampaign
                      className='h-100'
                      description='Total Campaign'
                      color='#F1416C'
                      img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                      dashData={dashData}
                    />
                  </>
              }
            </div>

            <div className='col-md-6'
              style={{ height: '185px' }}
            >
              {
                loading ? <></> :
                  <>
                    <TotalBooking className=''
                      dashData={dashData}
                    />
                  </>
              }
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              {
                loading ? <></> :
                  <>
                    <InfluencerJoin
                      className=''
                      color='success'
                      title='Influencer'
                      description='Weekly Influencer Booking'
                      change='+100'
                      dashData={dashData}
                    />
                  </>
              }
            </div>
          </div>
        </div>

        <div className='col-xxl-6'>
          {
            loading ? <></> :
              <>
                <CampaignChart className='h-md-100'
                  dashData={dashData}
                />
              </>
          }
        </div>
      </div>
    </>
  )
}

export { DashboardWrapper }
