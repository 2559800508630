/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import SubscriptionPackages from '../pages/subscription-packages'
import Category from '../pages/category'
import Brand from '../pages/brand'

import Campaign from '../pages/campaign'
import BookingList from '../pages/campaign/booking-list'
import CreateNewCampaign from '../pages/campaign/add-campaign'
import Voucher from '../pages/campaign/voucher'

import AccountManager from '../pages/account-manager'
import AddNewManager from '../pages/account-manager/add-new-manager'

import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Tutorials from '../pages/Tutorials/index'

import MyProfile from '../pages/MyProfile/index'
import MyProfileUpdate from '../pages/MyProfile/MyProfileUpdate'

import Influencer from '../pages/campaign/Influencer'
import Tickets from '../pages/support/Index'
import OpenTicket from '../pages/support/OpenTicket'
import TicketView from '../pages/support/TicketView'
import Faqs from '../pages/Faqs'
import About from '../pages/About'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import MissionVision from '../pages/MissionVision'
import Invitation from '../pages/campaign/Invitation'
import SentRequest from '../pages/campaign/Invitation/SentRequest'
import SuspenseInfluencer from '../pages/campaign/Suspense'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/login' element={<Navigate to='/dashboard' />} />
        <Route path='/registration' element={<Navigate to='/dashboard' />} />
        <Route path='/forgot-password' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='subscription-packages' element={<SubscriptionPackages />} />
        <Route path='category' element={<Category />} />
        <Route path='brand' element={<Brand />} />
        <Route path='tutorials' element={<Tutorials />} />
        <Route path='influencer' element={<Influencer />} />

        <Route path='suspense-influencer' element={<SuspenseInfluencer />} />

        {/* <Route path='invitation' element={<Invitation />} /> */}

        <Route path='faqs' element={<Faqs />} />
        <Route path='about' element={<About />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='mission-vision' element={<MissionVision />} />

        <Route path='campaign/*'>
          <Route index element={<Campaign />} />
          <Route path=':id' element={<BookingList />} />
          <Route path=':id/invitation' element={<Invitation />} />
          <Route path=':id/sent-request' element={<SentRequest />} />
          <Route path='add-campaign' element={<CreateNewCampaign />} />

          {/* <Route path='voucher' element={<Voucher />} /> */}
        </Route>

        <Route path='my-profile/*'>
          <Route index element={<MyProfile />} />
          <Route path='update' element={<MyProfileUpdate />} />
        </Route>

        <Route path='support/*'>
          <Route index element={<Tickets />} />
          <Route path='tickets' element={<Tickets />} />
          <Route path='view-ticket/:id' element={<TicketView />} />
          <Route path='open-ticket' element={<OpenTicket />} />
        </Route>

        <Route path='account-manager/*'>
          <Route index element={<AccountManager />} />
          <Route path='add-new-manager' element={<AddNewManager />} />
          {/* <Route path=':id' element={<EditManager />} /> */}
        </Route>

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
