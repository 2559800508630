/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'

import moment from 'moment'
import { parseCookies } from 'nookies'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Table from 'react-bootstrap/Table'
import Pagination from 'replace-js-pagination'
import { SidebarFooter } from '../../../_metronic/layout/components/sidebar/SidebarFooter'
import { baseUrl, APIURL } from '../../../config/config'
import InfluencerSkeleton from '../campaign/Influencer/InfluencerSkeleton'

const Tickets: FC = () => {
  const navigate = useNavigate()
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [conLoading, setConLoading] = useState(false)
  const [edit, setEdite] = useState(false)
  const [ticketsList, setTicketsList] = useState([])

  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerpage] = useState(0)
  const [lastPage, setLastPage] = useState(0)

  const fetchTickets = async (page: number) => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/admin/my-tickets?page=${page}`, requestOptions)
    const result = await response.json()

    console.log('result', result)

    if (result.success == true) {
      setTicketsList(result?.data)
      setTotal(result?.paginate?.total)
      setCurrentPage(result?.paginate?.current_page)
      setPerpage(result?.paginate?.per_page)
      setLastPage(result?.paginate?.last_page)
    }
    setLoading(false)
    // console.log('result', result);
  }

  useEffect(() => {
    fetchTickets(1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [activePage, setActivePage] = useState(1)

  const handlePageChange = (page: number) => {
    setActivePage(page)
    setLoading(true)
    fetchTickets(page)
    setLoading(false)
  }

  // console.log('brand', brand)

  const handleViewTicket = (ticket_no: any) => {
    navigate(`/support/view-ticket/${ticket_no}`)
  }

  return (
    <>
      <PageTitle>My Support Tickets</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-success py-5'>
              <h3 className='card-title fw-bolder text-white'>Tickets List</h3>
              <SidebarFooter />
            </div>
            <div className='card-body p-2'>
              <div className='row mb-4'>
                <div className='col-sm-12'>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        {/* <th className='fs-4'>#ID</th> */}
                        <th className='fs-4'>Subject</th>
                        <th className='fs-4'>Category</th>
                        <th className='fs-4 text-center'>Status</th>
                        <th className='fs-4 text-center'>Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <>
                          {new Array(5).fill(0).map((_, index) => (
                            <InfluencerSkeleton column={3} height={20} key={index} />
                          ))}
                        </>
                      )}

                      {ticketsList.map((item: any, index: any) => (
                        <tr
                          key={`${item.id}.${index}`}
                          onClick={() => handleViewTicket(item.ticket_no)}
                          style={{ cursor: 'pointer' }}
                        >
                          {/* <th>#{item.ticket_no}</th> */}
                          <td className='text-center'>{item.issue_subject}</td>
                          <td>{item.category}</td>
                          <td className='text-center'>
                            {item.status == 1 ? 'Active' : ''}
                            {item.status == 2 ? 'Complete' : ''}
                            {item.status == 0 ? 'Closed' : ''}
                          </td>
                          <td className='text-center'>{moment(item.created_at).format('LLLL')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className='col-sm-12'></div>
              </div>
            </div>
            <div className='card-footer p-4 mb-2 bg-secondary'>
              <div className='d-flex flex-stack flex-wrap'>
                <div className='fs-6 fw-bold text-gray-700'>
                  Showing {currentPage} to {perPage} of {total} entries
                </div>
                {/*begin::Pages*/}
                <div className='pagination'>
                  <Pagination
                    hideFirstLastPages={false}
                    hideNavigation={true}
                    linkClassFirst='next'
                    linkClassPrev='previous'
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
                {/*end::Pages*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default Tickets
