/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import InfluencerSkeleton from './InfluencerSkeleton'
import {Table} from 'react-bootstrap'
import {parseCookies} from 'nookies'
import {APIURL} from '../../../../config/config'
import Pagination from 'replace-js-pagination'

import {FaStar} from 'react-icons/fa'

interface RProps {
  show?: boolean
  onHide?: () => void
  influencer_id?: string
}

const ReviewTable = (props: RProps) => {
  const {influencer_id} = props
  const cookies = parseCookies()
  const token = cookies?.token
  const [reviewList, setReviewList] = React.useState<any>([])

  const [total, setTotal] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [perPage, setPerpage] = React.useState<number>(0)
  const [lastPage, setLastPage] = React.useState<number>(0)
  const [activePage, setActivePage] = React.useState<number>(1)
  const [nextPage, setNextPage] = React.useState<boolean>(false)
  const [prevPage, setPrevPage] = React.useState<boolean>(false)

  const [loading, setLoading] = React.useState<boolean>(true)

  const fetchReviews = async () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(
      `${APIURL}/merchant/review?page=${currentPage}&influencer_id=${influencer_id}`,
      requestOptions
    )
    const result = await response.json()
    if (result.success === true) {
      setReviewList(result?.data)

      setActivePage(result?.paginate?.current_page)
      setTotal(result?.paginate?.total)
      setCurrentPage(result?.paginate?.current_page)
      setPerpage(result?.paginate?.per_page)
      setLastPage(result?.paginate?.last_page)
      if (result?.paginate?.next_url === null) {
        setNextPage(false)
      } else {
        setNextPage(true)
      }
      if (result?.paginate?.prev_url === null) {
        setPrevPage(false)
      } else {
        setPrevPage(true)
      }
    }
    setLoading(false)
  }

  React.useEffect(() => {
    fetchReviews()
  }, [])

  const handlePageChange = (page: number) => {
    if (nextPage) {
      setCurrentPage((pre?: any) => pre + 1)
    }

    if (prevPage) {
      setCurrentPage((pre?: any) => pre - 1)
    }
  }

  return (
    <>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th className='fs-4 text-center'>Campaign Name</th>
            <th className='fs-4 text-center'>Ratings</th>
            <th className='fs-4 text-center'>Review</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <>
              {new Array(2).fill(0).map((item, index) => (
                <InfluencerSkeleton height={20} column={1} key={index} />
              ))}
            </>
          )}

          {!loading && reviewList?.length === 0 && (
            <tr>
              <td colSpan={3} className='text-center'>
                No reviews found
              </td>
            </tr>
          )}

          {!loading &&
            reviewList &&
            reviewList?.map((item: any, index: any) => (
              <tr key={`${item.campaign_name}.${index}`} className='align-middle'>
                <td className='text-center'>{item?.campaign_name}</td>
                <td className='text-center'>
                  <FaStar
                    style={{
                      color: '#FFD700',
                    }}
                  />{' '}
                  {item?.rating}
                </td>
                <td className='text-center'>{item?.review}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className='card-footer p-4 mb-2 bg-secondary'>
        <div className='d-flex flex-stack flex-wrap'>
          <div className='fs-6 fw-bold text-gray-700'>
            Showing {currentPage} to {perPage} of {total} entries
          </div>

          <div className='pagination'>
            <Pagination
              hideFirstLastPages={false}
              hideNavigation={true}
              linkClassFirst='next'
              linkClassPrev='previous'
              itemClass='page-item'
              linkClass='page-link'
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewTable
