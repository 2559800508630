/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {NavLink} from 'react-router-dom'

import {useState, useEffect} from 'react'
import {BsFillSunFill, BsMoonStarsFill} from 'react-icons/bs'
import {MdOutlineReviews} from 'react-icons/md'

import {IoPersonAddSharp} from 'react-icons/io5'
import {FaUserCheck} from 'react-icons/fa'

import {parseCookies} from 'nookies'

import moment from 'moment'

import {PageTitle} from '../../../_metronic/layout/core'
import {APIURL} from '../../../config/config'
import CamStatus from '../../../app/components/CamStatus'
import InfluencerSkeleton from './Influencer/InfluencerSkeleton'
import {Button} from 'react-bootstrap'
import CampaignReviews from './CampaignReviews'

const Campaign: FC = () => {
  const cookies = parseCookies()
  const token = cookies?.token

  const [open, setModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  console.log('🚀 ~ file: index.tsx:31 ~ campaigns:', campaigns)
  const [campaignId, setCampaignId] = useState('')

  const getCampaigns = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/campaigns`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      setCampaigns(result?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCampaigns()
  }, [])

  const Icon = () => {
    return (
      <svg
        aria-hidden='true'
        className='flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500'
        fill='currentColor'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <title>Check icon</title>
        <path
          fill-rule='evenodd'
          d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
          clip-rule='evenodd'
        ></path>
      </svg>
    )
  }

  return (
    <>
      <CampaignReviews show={show} campaign_id={campaignId} onHide={() => setShow(false)} />

      <PageTitle>Campaign List</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card'>
            <div className='card-body pt-0'>
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    id='kt_subscriptions_table'
                  >
                    <thead>
                      <tr className='text-start text-dark fw-bolder fs-7 text-capitalize gs-0'>
                        <th className='min-w-125px sorting'>Image</th>
                        <th className='min-w-125px sorting'>Campaign Name</th>
                        <th className='min-w-125px sorting'>Merchant</th>
                        <th className='min-w-125px sorting'>Category</th>
                        <th className='min-w-125px sorting'>Date</th>
                        <th className='min-w-70px sorting_disabled text-center'>Status</th>
                        <th className='min-w-125px  sorting_disabled text-center'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                      {loading ? (
                        <>
                          {new Array(10).fill(0).map((_, index) => (
                            <InfluencerSkeleton key={index} />
                          ))}
                          {/* <div className='d-flex justify-content-center'>
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div> */}
                        </>
                      ) : campaigns.length === 0 ? (
                        <tr>
                          <td colSpan={6} className='text-center'>
                            No Data Found
                          </td>
                        </tr>
                      ) : (
                        <>
                          {campaigns.map((item: any, index: any) => (
                            <tr className='odd align-middle' key={`${item.id}.${index}`}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-45px me-5'>
                                    <picture>
                                      <img
                                        src={item.thumb_image}
                                        alt=''
                                        style={{width: '45px', height: '45px'}}
                                      />
                                    </picture>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-center flex-column'>
                                    <a
                                      href='#'
                                      className='text-dark fw-bolder text-hover-primary fs-6'
                                    >
                                      {item.name}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td>{item.merchant_name}</td>
                              <td>{item.category_name}</td>
                              <td>
                                {moment(item.start_date).format('Do, MMMM YYYY')} -{' '}
                                {moment(item.end_date).format('Do, MMMM YYYY')}
                              </td>
                              <td>
                                <span className='mr-2 h-8 d-flex align-items-center justify-content-center'>
                                  <CamStatus id={item.id} status={item.status} />
                                </span>
                                {/* {item.status == 1 ? <><span className="btn btn-success btn-sm">
                                  Active
                                </span></> : <><span className="btn btn-danger btn-sm">
                                  Inactive
                                </span></>} */}
                              </td>
                              <td
                                className='d-flex gap-3 align-items-center justify-content-center'
                                style={{
                                  minHeight: '80px',
                                }}
                              >
                                <NavLink
                                  to={`/campaign/${item.id}`}
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                >
                                  View
                                </NavLink>

                                <Button
                                  variant='light'
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                  onClick={() => {
                                    setShow(true)
                                    setCampaignId(item.id)
                                  }}
                                  title='Reviews'
                                >
                                  <MdOutlineReviews />
                                </Button>

                                {/* if end date is available in my current date then show button */}
                                {new Date(item?.end_date).getTime() >= new Date().getTime() && (
                                  <NavLink
                                    to={`/campaign/${item.id}/invitation`}
                                    className='btn btn-primary btn-active-light-primary btn-sm'
                                    style={{
                                      whiteSpace: 'nowrap',
                                    }}
                                    title='Invite Influencer'
                                  >
                                    {/* Invite Influencer */}
                                    <IoPersonAddSharp />
                                  </NavLink>
                                )}

                                {new Date(item?.end_date).getTime() >= new Date().getTime() && (
                                  <NavLink
                                    to={`/campaign/${item.id}/sent-request`}
                                    className='btn btn-primary btn-active-light-primary btn-sm'
                                    style={{
                                      whiteSpace: 'nowrap',
                                    }}
                                    title='Sent Requests'
                                  >
                                    {/* Sent Requests */}
                                    <FaUserCheck />
                                  </NavLink>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <div className="row">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="kt_subscriptions_table_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="kt_subscriptions_table_previous"
                        >
                          <a
                            href="#"
                            aria-controls="kt_subscriptions_table"
                            data-dt-idx={0}
                            tabIndex={0}
                            className="page-link"
                          >
                            <i className="previous" />
                          </a>
                        </li>
                        <li className="paginate_button page-item active">
                          <a
                            href="#"
                            aria-controls="kt_subscriptions_table"
                            data-dt-idx={1}
                            tabIndex={0}
                            className="page-link"
                          >
                            1
                          </a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a
                            href="#"
                            aria-controls="kt_subscriptions_table"
                            data-dt-idx={2}
                            tabIndex={0}
                            className="page-link"
                          >
                            2
                          </a>
                        </li>
                        <li
                          className="paginate_button page-item next"
                          id="kt_subscriptions_table_next"
                        >
                          <a
                            href="#"
                            aria-controls="kt_subscriptions_table"
                            data-dt-idx={3}
                            tabIndex={0}
                            className="page-link"
                          >
                            <i className="next" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default Campaign
