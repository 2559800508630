import * as React from 'react'
import {parseCookies} from 'nookies'
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap'
import {APIURL, baseUrl} from '../../../config/config'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface AddTutorialDialogProps {
  show: boolean
  onHide: () => void
}

const AddTutorialDialog: React.FC<AddTutorialDialogProps> = (props) => {
  const initialTutorialState = {
    description: '',
    type: '',
    file: '',
    directory_name: 'tutorial',
    thumbnail: '',
    video: '',
  }

  const [tutorialState, setTutorialState] = React.useState<any>(initialTutorialState)

  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const uploadTutorial = async () => {
    setLoading(true)

    const getFileUrl = async (file: any) => {
      const pdfType = file?.type === 'application/pdf'
      const imageType = file?.type === 'image/png' || file?.type === 'image/jpeg'
      const videoType = file?.type === 'video/mp4' || file?.type === 'video/webm'

      let fileType = ''
      if (pdfType) {
        fileType = 'pdf'
      } else if (imageType) {
        fileType = 'image'
      } else if (videoType) {
        fileType = 'video'
      } else {
        return toast.error('Invalid file type')
      }

      setError('')
      try {
        var myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${token}`)

        const convertFile = async (file: any) => {
          if (
            file?.type === 'application/pdf' ||
            file?.type === 'image/png' ||
            file?.type === 'image/jpeg' ||
            file?.type === 'image/jpg'
          ) {
            const base64 = await new Promise((resolve) => {
              const reader = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = () => {
                const base64 = reader.result?.toString().split(',')[1]
                resolve(base64)
              }
              reader.onerror = (error) => {
                setError('something went wrong')
              }
            })
            return base64
          } else {
            return file
          }
        }

        const formData = new FormData()
        formData.append('file', await convertFile(file))
        formData.append('directory_name', tutorialState?.directory_name)
        formData.append('type', fileType)

        const requestOptions: any = {
          method: 'POST',
          body: formData,
          headers: myHeaders,
          redirect: 'follow',
        }

        const response = await fetch(`${APIURL}/admin/upload-file`, requestOptions)
        const result = await response.json()
        if (result?.success) {
          return result?.data?.file_url
        }
      } catch (error) {
        setError('something went wrong')
      }
    }

    try {
      if (!tutorialState?.file) return toast.error('Please select a file')
      if (!tutorialState?.description) {
        setLoading(false)
        return toast.error('Please enter description')
      }

      const res = await getFileUrl(
        tutorialState?.file?.type === 'application/pdf' ||
          tutorialState?.file?.type === 'image/png' ||
          tutorialState?.file?.type === 'image/jpeg' ||
          tutorialState?.file?.type === 'image/jpg' ||
          tutorialState?.file?.type === 'video/mp4' ||
          tutorialState?.file?.type === 'video/webm'
          ? tutorialState?.file
          : tutorialState?.thumbnail
      )

      let thumbnailRes = ''

      if (tutorialState?.type === 'video') {
        thumbnailRes = await getFileUrl(
          tutorialState?.file?.type === 'video/mp4' || tutorialState?.file?.type === 'video/webm'
            ? tutorialState?.thumbnail
            : ''
        )
      }

      var myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${token}`)

      const formData = new FormData()
      formData.append('type', tutorialState?.type)
      formData.append('doc_url', res)
      formData.append('description', tutorialState?.description)

      if (thumbnailRes !== '') {
        formData.append('screenshot', thumbnailRes)
      }

      const requestOptions: any = {
        method: 'POST',
        body: formData,
        headers: myHeaders,
        redirect: 'follow',
      }

      if (res) {
        const response = await fetch(`${APIURL}/merchant/save-tutorial`, requestOptions)
        const result = await response.json()

        if (result?.success) {
          toast.success(result?.message)
        }
      }
    } catch (error) {
      setError('something went wrong')
    }

    setLoading(false)
    props.onHide()
    setTutorialState(initialTutorialState)
  }

  return (
    <Modal {...props} size='lg' aria-labelledby='add-tutorial-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='add-tutorial-modal'>Add Tutorial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formFile'>
            <Form.Control
              type='file'
              name='file'
              onChange={(e: any) => {
                setTutorialState({
                  ...tutorialState,
                  file: e.target.files[0],
                  type:
                    e.target.files[0].type === 'application/pdf'
                      ? 'pdf'
                      : e.target.files[0].type === 'image/png' ||
                        e.target.files[0].type === 'image/jpeg'
                      ? 'image'
                      : 'video',
                })
              }}
            />
          </Form.Group>

          {tutorialState?.file?.type === 'image/png' ||
          tutorialState?.file?.type === 'image/jpeg' ||
          tutorialState?.file?.type === 'image/jpg' ? (
            <div className='d-flex justify-content-center mb-2 mt-4 h-100px w-100px'>
              <img
                src={URL.createObjectURL(tutorialState?.file)}
                alt='preview'
                className='img-fluid'
              />
            </div>
          ) : (
            <></>
            // <div className='h-100px w-100px bg-active-info border-2 border-active-info'>
            //   <img src={`${baseUrl}/assets/img/thum.jpg`} className='img-fluid' alt='thumb' />
            // </div>
          )}

          <Form.Select
            className='mb-5 mt-4'
            aria-label='Select type of file'
            value={tutorialState.type}
            onChange={(e) => setTutorialState({...tutorialState, type: e.target.value})}
            disabled={true}
          >
            <option>Select type of file</option>
            <option value='video'>Video</option>
            <option value='image'>Image</option>
            <option value='pdf'>PDF</option>
          </Form.Select>

          {
            // if type video then show input for video
            tutorialState?.type === 'video' && (
              <Form.Group className='mb-5' controlId='rating.description'>
                <Form.Label>Thumbnail</Form.Label>
                <Form.Control
                  type='file'
                  name='thumbnail'
                  onChange={(e: any) => {
                    setTutorialState({
                      ...tutorialState,
                      thumbnail: e.target.files[0],
                    })
                  }}
                />
              </Form.Group>
            )
          }

          {/* preview image */}
          {tutorialState?.thumbnail?.type === 'image/png' ||
          tutorialState?.thumbnail?.type === 'image/jpeg' ? (
            <div className='d-flex justify-content-center mb-2 h-100px w-100px'>
              <img
                src={URL.createObjectURL(tutorialState?.thumbnail)}
                alt='preview'
                className='img-fluid'
              />
            </div>
          ) : (
            <></>
            // <div className='h-100px w-100px bg-active-info border-2 border-active-info'>
            //   <img src={`${baseUrl}/assets/img/thum.jpg`} className='img-fluid' alt='thumb' />
            // </div>
          )}

          <Form.Group className='mb-5 mt-4' controlId='rating.description'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              value={tutorialState.description}
              onChange={(e) => setTutorialState({...tutorialState, description: e.target.value})}
              as='textarea'
              rows={3}
            />
          </Form.Group>
        </Form>

        <div className='d-flex justify-content-center'>
          <Button variant='success' size='lg' disabled={loading} onClick={() => uploadTutorial()}>
            {loading ? <Spinner animation='border' size='sm' /> : 'Save'}
          </Button>
        </div>

        {error && (
          <Alert className='mt-4' variant='danger'>
            {error}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AddTutorialDialog
