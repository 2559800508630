/* eslint-disable eqeqeq */
import React from 'react'
import { parseCookies } from "nookies";
import Swal from 'sweetalert2'

interface ContentProps {
    item?: any;
    makePayment?: any;
    purchaseList?: any;
    getPackages?: any;
}

const API_URL = process.env.REACT_APP_API_URL

const SubscriptionContent: React.FC<ContentProps> = (props: ContentProps) => {
    const { item, makePayment, purchaseList, getPackages } = props;

    const cookies = parseCookies();
    const token = cookies?.token;

    const Icon = () => {
        return (
            <svg
                aria-hidden="true"
                className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>Check icon</title>
                <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                ></path>
            </svg>
        );
    };


    const RenewCancel = (id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you turn off subscription package auto renewal',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then(async (result) => {
            console.log('result', result);
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                var myHeaders = new Headers();

                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("Content-Type", "application/json");

                var requestOptions: any = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                const response = await fetch(
                    `${API_URL}/merchant/cancel-subscription?id=${id}`,
                    requestOptions
                );
                const result = await response.json();
                if (response.status == 200) {
                    if (result.success == true) {
                        getPackages();
                        Swal.fire(`${result.message}!`, '', 'success')
                    }
                }

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        });
    }


    console.log('purchaseList', purchaseList)

    return (
        <>
            {
                item.status == 1 ? <>
                    <div className="col-xl-4">
                        <div className="d-flex h-100 align-items-center">
                            <div className="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                <div className="mb-7 text-center">
                                    <h1 className="text-dark mb-5 fw-boldest">
                                        {item.title}
                                    </h1>
                                    <h5 className="text-dark mb-5 fw-boldest">
                                        {item.type}
                                    </h5>
                                    <div className="text-center">
                                        <span className="mb-2 text-primary">$</span>
                                        <span className="fs-3x fw-bolder text-primary">
                                            {item.price}
                                        </span>
                                        {/* <span className="fs-7 fw-bold opacity-50">
                                        /<span data-kt-element="period">Mon</span>
                                      </span> */}
                                    </div>
                                </div>
                                <div
                                    className="w-100 mb-10"
                                    style={{ height: '150px' }}
                                >
                                    {item.features.map(
                                        (feature: any, index: any) => (
                                            <div
                                                key={`${feature.id}.${index}`}
                                                className="d-flex align-items-center mb-5"
                                            >
                                                <span className="svg-icon svg-icon-1 svg-icon-success pe-2">
                                                    {Icon()}
                                                </span>
                                                <span className="fw-bold fs-6 text-gray-800 flex-grow-1">
                                                    {feature.content}
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>

                                <div className="w-100 d-flex justify-content-center">
                                    {
                                        purchaseList == '' ?
                                            <>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => makePayment(item.id)}
                                                > Choose Plan
                                                </button>
                                            </> : <>
                                                {
                                                    purchaseList.subscription_id == item.id ?
                                                        <>
                                                            <button
                                                                className="btn btn-primary" disabled
                                                            > Purchased
                                                            </button>
                                                            <>
                                                                {
                                                                    purchaseList.status == '0' ?
                                                                        <>
                                                                        </> : <>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                style={{
                                                                                    marginLeft: '5px'
                                                                                }}
                                                                                onClick={() => RenewCancel(purchaseList.id)}
                                                                            > Auto Renew Cancel
                                                                            </button>
                                                                        </>
                                                                }
                                                            </>
                                                        </> :
                                                        <>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => makePayment(item.id)}
                                                            > Upgrade Plan
                                                            </button>
                                                        </>

                                                }
                                            </>
                                    }
                                    {/* <Link
                                      href={`/subscription-packages/update-pachage/${item.id}`}
                                      className="btn btn-outline-dark border border-dark border-1 mr-2"
                                      style={{ marginRight: '5px' }}
                                    >
                                      Edit Plan
                                    </Link>
                                    <Link
                                      href={`/subscription-packages/subscription-rules/${item.id}`}
                                      className="btn btn-outline-dark border border-dark border-1"
                                    >
                                      Rules
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div></> : <></>
            }
        </>
    )
}

export default SubscriptionContent
