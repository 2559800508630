import Modal from 'react-bootstrap/Modal'
import * as React from 'react'
import {APIURL} from '../../../config/config'
import useSWR from 'swr'
import axios from 'axios'
import {Button} from 'react-bootstrap'
import InfluencerSkeleton from './Influencer/InfluencerSkeleton'
import {toast} from 'react-toastify'
import {parseCookies} from 'nookies'
import {FaStar} from 'react-icons/fa'
import Pagination from 'replace-js-pagination'

interface ReviewDialogProps {
  show: boolean
  onHide: () => void
  influencer_id?: string
  campaign_id?: string
}

const CampaignReviews = (props: ReviewDialogProps) => {
  const {campaign_id} = props

  const [total, setTotal] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [perPage, setPerpage] = React.useState<number>(0)
  const [lastPage, setLastPage] = React.useState<number>(0)
  const [activePage, setActivePage] = React.useState<number>(1)
  const [nextPage, setNextPage] = React.useState<boolean>(false)
  const [prevPage, setPrevPage] = React.useState<boolean>(false)

  const fetcher = async (url: string) => {
    const {data} = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${parseCookies()?.token}`,
      },
    })

    if (data.success === true) {
      setActivePage(data?.paginate?.current_page)
      setTotal(data?.paginate?.total)
      setCurrentPage(data?.paginate?.current_page)
      setPerpage(data?.paginate?.per_page)
      setLastPage(data?.paginate?.last_page)
      if (data?.paginate?.next_url === null) {
        setNextPage(false)
      } else {
        setNextPage(true)
      }
      if (data?.paginate?.prev_url === null) {
        setPrevPage(false)
      } else {
        setPrevPage(true)
      }
    }

    return data?.data
  }

  const {data, error, isLoading, mutate} = useSWR(
    `${APIURL}/review/campaign-reviews/${campaign_id}?page=${currentPage}`,
    fetcher
  )

  return (
    <Modal {...props} size='lg' aria-labelledby='rating-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='rating-modal'>Campaign Review List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReviewTable
          data={data}
          loading={isLoading}
          mutate={mutate}
          error={error}
          total={total}
          currentPage={currentPage}
          perPage={perPage}
          lastPage={lastPage}
          activePage={activePage}
          nextPage={nextPage}
          prevPage={prevPage}
          setCurrentPage={setCurrentPage}
        />
      </Modal.Body>
    </Modal>
  )
}

export default CampaignReviews

interface ReviewTableProps {
  data: any[]
  loading: boolean
  mutate?: any
  error?: any
  total: number
  currentPage?: any
  perPage?: any
  lastPage?: any
  activePage?: any
  nextPage?: any
  prevPage?: any
  setCurrentPage?: any
}

const ReviewTable = ({
  data,
  loading,
  mutate,
  error,
  total,
  currentPage,
  perPage,
  lastPage,
  activePage,
  nextPage,
  prevPage,
  setCurrentPage,
}: ReviewTableProps) => {
  const cookies = parseCookies()
  const token = cookies?.token

  const changeStatus = async (id: string) => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    const response = await fetch(`${APIURL}/merchant/change-status?review_id=${id}`, {
      headers: myHeaders,
    })

    const result = await response.json()
    if (result.success === true) {
      mutate()
    } else {
      toast?.error(result?.message)
    }
  }

  const handlePageChange = (page: number) => {
    if (nextPage) {
      setCurrentPage((pre?: any) => pre + 1)
    }

    if (prevPage) {
      setCurrentPage((pre?: any) => pre - 1)
    }
  }

  return (
    <div className='row g-5 g-xl-10'>
      <div className='col-sm-12'>
        <div className='card'>
          <div className='card-body pt-0'>
            <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
              <div className='table-responsive'>
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                  id='kt_subscriptions_table'
                >
                  <thead>
                    <tr className='text-start text-dark fw-bolder fs-7 text-uppercase gs-0'>
                      <th className='min-w-125px sorting'>Influencer Name</th>
                      <th className='min-w-125px sorting'>Profile</th>
                      <th className='min-w-125px sorting'>Ratings</th>
                      <th className='min-w-70px sorting_disabled'>Review</th>
                      <th className='min-w-70px sorting_disabled'>Action</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600  fw-bold'>
                    {error && (
                      <tr>
                        <td colSpan={5} className='text-center'>
                          {error?.message || 'Something went wrong'}
                        </td>
                      </tr>
                    )}

                    {loading ? (
                      <>
                        {new Array(5).fill(0).map((_, index) => (
                          <InfluencerSkeleton column={3} height={20} key={index} />
                        ))}
                      </>
                    ) : !loading && !data.length ? (
                      <tr>
                        <td colSpan={5} className='text-center'>
                          No Review Found
                        </td>
                      </tr>
                    ) : (
                      <>
                        {data?.map((item: any) => (
                          <tr key={item.id}>
                            <td>{item?.influencer_name}</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px me-5'>
                                  <img src={item.photo_url} alt='' />
                                </div>
                              </div>
                            </td>
                            <td>
                              <FaStar
                                style={{
                                  color: '#FFD700',
                                }}
                              />{' '}
                              {item?.rating}
                            </td>
                            <td>{item?.review}</td>
                            <td>
                              <Button
                                className='btn btn-light btn-sm'
                                size='sm'
                                onClick={() => changeStatus(item?.id)}
                              >
                                Hide
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='col-sm-12'></div>
            </div>
          </div>

          <div className='card-footer p-4 mb-2 bg-secondary'>
            <div className='d-flex flex-stack flex-wrap'>
              <div className='fs-6 fw-bold text-gray-700'>
                Showing {currentPage} to {perPage} of {total} entries
              </div>

              <div className='pagination'>
                <Pagination
                  hideFirstLastPages={false}
                  hideNavigation={true}
                  linkClassFirst='next'
                  linkClassPrev='previous'
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
