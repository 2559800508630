/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

import {baseUrl, APIURL} from '../../../config/config'

import {parseCookies} from 'nookies'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import AddBrands from './AddBrands'

const AddNewManager: FC = () => {
  const navigate = useNavigate()
  const cookies = parseCookies()
  const token = cookies?.token

  const [loading, setLoading] = useState(true)
  const [conLoading, setConLoading] = useState(false)

  const [imgLoading, setImgLoading] = useState(false)
  const [logoImg, setLogoImg] = useState('')

  const [manager, setManager] = useState({
    f_name: '',
    l_name: '',
    email: '',
    phone: '',
    address: '',
    profile_photo: '',
    brands: [],
  })
  // console.log('🚀 ~ file: add-new-manager.tsx:35 ~ manager:', manager)

  const updateBrand = (e: {target: {name: any; value: any}}) => {
    setManager({...manager, [e.target.name]: e.target.value})
  }

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      // let search = reader.result.search("data:image/jpeg;base64,", "")
      let imageFile

      //@ts-ignore
      if (reader.result.search('data:image/jpeg;base64,') == 0) {
        //@ts-ignore
        imageFile = reader.result.replace('data:image/jpeg;base64,', '')
        //@ts-ignore
      } else if (reader.result.search('data:image/png;base64,') == 0) {
        //@ts-ignore
        imageFile = reader.result.replace('data:image/png;base64,', '')
      }

      //@ts-ignore
      setLogoImg(reader.result)

      setManager({
        ...manager,
        [name]: imageFile,
      })

      setImgLoading(false)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  async function SubmitHandler(e: any) {
    e.preventDefault()
    if (manager.brands.length === 0) {
      toast.error('Please select brands')
      return
    }

    setConLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions: any = {
      method: 'POST',
      body: JSON.stringify({
        ...manager,
      }),
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/merchant/staff`, requestOptions)
    const result = await response.json()

    // console.log("response.status: ", response);
    if (response.status === 200) {
      if (result.success === true) {
        toast.success(result.message)
        navigate('/account-manager')
        setManager({
          ...manager,
          f_name: '',
          l_name: '',
          email: '',
          phone: '',
          address: '',
          profile_photo: '',
          brands: [],
        })
        setLogoImg('')
      }
    } else if (response.status === 415) {
      if (result?.message?.f_name?.length > 0) {
        toast.error(result?.message?.f_name[0])
      }

      if (result?.message?.l_name?.length > 0) {
        toast.error(result?.message?.l_name[0])
      }

      if (result?.message?.email?.length > 0) {
        toast.error(result?.message?.email[0])
      }

      if (result?.message?.phone?.length > 0) {
        toast.error(result?.message?.phone[0])
      }

      if (result?.message?.address?.length > 0) {
        toast.error(result?.message?.address[0])
      }

      if (result?.message?.profile_photo?.length > 0) {
        toast.error(result?.message?.profile_photo[0])
      }

      if (result?.message?.brands?.length > 0) {
        toast.error(result?.message?.brands[0])
      }
    }
    setConLoading(false)
  }

  const getBrands = (brands: any) => {
    setManager({
      ...manager,
      brands: brands,
    })
  }

  return (
    <>
      <PageTitle>Add New Manager</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch'>
            <div className='card-header border-0 bg-info py-5'>
              <h3 className='card-title fw-bolder text-white'>Add Manager</h3>
            </div>
            <div className='card-body p-2'>
              <form className='p-4 z-40' onSubmit={SubmitHandler}>
                <div className='row'>
                  <div className='col-sm-6 col-md-6'>
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                      <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        value={manager.f_name}
                        name='f_name'
                        onChange={updateBrand}
                        placeholder='First Name'
                        autoComplete='off'
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6'>
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        value={manager.l_name}
                        name='l_name'
                        onChange={updateBrand}
                        placeholder='Last Name'
                        autoComplete='off'
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-6 col-md-6'>
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        value={manager.email}
                        name='email'
                        onChange={updateBrand}
                        placeholder='Email'
                        autoComplete='off'
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6'>
                    {' '}
                    <div className='fv-row mb-10 fv-plugins-icon-container'>
                      <label className='form-label fs-6 fw-bolder text-dark'>Phone</label>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        value={manager.phone}
                        name='phone'
                        onChange={updateBrand}
                        placeholder='Phone'
                        autoComplete='off'
                      />
                      <div className='fv-plugins-message-container invalid-feedback'></div>
                    </div>
                  </div>
                </div>

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Address</label>
                  <textarea
                    defaultValue=''
                    name='address'
                    value={manager.address}
                    onChange={updateBrand}
                    className='form-control form-control-lg form-control-solid'
                    rows={4}
                    cols={40}
                  />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <AddBrands getBrands={getBrands} />

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Profile Photo</label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='file'
                    name='profile_photo'
                    onChange={(e) => uploadFile(e)}
                  />
                  <picture className='mt-2 mb-2'>
                    {imgLoading ? (
                      <>
                        <div
                          className='d-flex justify-content-center img-fluid form-control mt-2 mb-2'
                          style={{width: '150px', height: '100px'}}
                        >
                          <div className='spinner-border' role='status'>
                            <span className='visually-hidden'>Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {logoImg === '' ? (
                          <>
                            <img
                              src={`${baseUrl}/assets/img/thum.jpg`}
                              className='img-fluid form-control mt-2 mb-2'
                              style={{width: '150px', height: '100px'}}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`${logoImg}`}
                              className='img-fluid form-control mt-2 mb-2'
                              style={{width: '150px', height: '100px'}}
                            />
                          </>
                        )}
                      </>
                    )}
                  </picture>
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <div>
                  {conLoading ? (
                    <>
                      <button className='btn btn-lg btn-primary w-100 mb-5' disabled>
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </button>
                    </>
                  ) : (
                    <>
                      <button className='btn btn-lg btn-primary w-100 mb-5'>Submit</button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default AddNewManager
