/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, FormEvent, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {parseCookies} from 'nookies'
import {baseUrl, APIURL} from '../../../config/config'

const MyProfile: FC = () => {
  const navigate = useNavigate()
  const cookies = parseCookies()
  const token = cookies?.token
  const {currentUser, logout} = useAuth()
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [imgsLoading, setImgsLoading] = useState(false)
  const [logoImg, setLogoImg] = useState('')

  const [user, setUser] = useState({
    f_name: '',
    l_name: '',
    email: '',
    phone: '',
    about: '',
    address: '',
    profile_photo: '',
    cover_photo: '',
  })

  const getUser = async () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    var requestOptions: any = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    const response = await fetch(`${APIURL}/me`, requestOptions)
    const result = await response.json()
    if (result.success == true) {
      setUser({
        ...user,
        f_name: result?.data?.user?.f_name,
        l_name: result?.data?.user?.l_name,
        email: result?.data?.user?.email,
        phone: result?.data?.user?.phone,
        about: result?.data?.user?.about,
        address: result?.data?.user?.address,
        profile_photo: '',
      })
      setLogoImg(result?.data?.user?.profile_photo)
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const updateUser = (e: {target: {name: any; value: any}}) => {
    setUser({...user, [e.target.name]: e.target.value})
  }

  function getBase64(name: string, file: any, file_name: string) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      let fileType = file.type
      if (fileType.match(/image/g) == 'image') {
        let databs64 = `data:${fileType};base64,`

        //@ts-ignore
        let imageFile = reader.result.replace(String(databs64), '')

        //@ts-ignore
        setLogoImg(reader.result)

        setUser({
          ...user,
          [name]: imageFile,
        })
        setImgLoading(false)
      } else {
        toast.error('Please select image file')
        setImgLoading(false)
      }
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const uploadFile = async (e: {
    target: {
      [x: string]: any
      name: any
      value: any
    }
  }) => {
    setImgLoading(true)
    getBase64(e.target.name, e.target.files[0], e.target.files[0].name)
  }

  async function handleProfileUpdate(e: FormEvent) {
    e.preventDefault()

    setLoading(true)

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({...user})

    var requestOptions: any = {
      method: 'PUT',
      body: raw,
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(`${APIURL}/profile-update`, requestOptions)
    const result = await response.json()

    if (result.success === true) {
      toast.success(result?.message)
      // navigate('/my-profile')
    } else if (result.success === false) {
      toast.error(`All field is required`)
    }
    setLoading(false)
  }

  console.log('user :-', user)

  return (
    <>
      <PageTitle>Update My Profile Update</PageTitle>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Profile Update</h3>
            </div>
          </div>

          <form onSubmit={handleProfileUpdate}>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>{/* Avatar */}</label>
                <div className='col-lg-8'>
                  {imgLoading ? (
                    <>
                      <div
                        className='d-flex justify-content-center img-fluid form-control'
                        style={{width: '150px', height: '100px'}}
                      >
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{
                        backgroundImage: `url(${logoImg})`,
                      }}
                    >
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{
                          backgroundImage: `url(${logoImg})`,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Profile Photo</label>
                <div className='col-lg-8'>
                  <input
                    type='file'
                    className='form-control mb-2'
                    name='profile_photo'
                    onChange={(e) => uploadFile(e)}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='First name'
                        name='f_name'
                        value={user.f_name}
                        onChange={updateUser}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Last name'
                        name='l_name'
                        value={user.l_name}
                        onChange={updateUser}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Contact Phone</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone number'
                    name='phone'
                    value={user.phone}
                    onChange={updateUser}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Address</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address'
                    name='address'
                    value={user.address}
                    onChange={updateUser}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>About</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    name='about'
                    defaultValue={user.about}
                    value={user.about}
                    onChange={updateUser}
                    className='form-control'
                    rows={4}
                    cols={40}
                  />
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              {loading ? (
                <button className='btn btn-lg btn-primary mb-5' disabled>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </button>
              ) : (
                <>
                  <button className='btn btn-lg btn-primary mb-5'>Save Changes</button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export default MyProfile
